// Read this
// https://docs.ethers.org/v5/single-page/#/v5/getting-started/
// https://testnet.layerzeroscan.com/address/0xba8ee378574e5b66a9b97995134c089c4d1b5141
// seriously, read it!
// listen Blockchain events, Query Blockchain specific block range, sign messages

import React, { createContext, useContext, useState, useEffect } from 'react';
import { ethers } from 'ethers';

import { abi as abiTelos } from '../lib/contracts/token_telos.json';
import { abi as abiBase } from '../lib/contracts/token_base.json';
import { abi as abiBridge } from '../lib/contracts/token_bridge.json';
import { getFloat, getNumber, compareObjects} from './utils';
import sleep from './sleep';

import QUDOServer from 'helpers/QUDOServerConnection';

const addressBridge = process.env.REACT_APP_CONTRACT_ADDRESS_TEVM_BRIDGE_TESTNET;
const addressBridger = process.env.REACT_APP_CONTRACT_ACCOUNT_EVM_BRIDGER_TESTNET;

const _chains = {
  "telosEVMTestnet": {
    chainId: 41,
    chainName: "Telos EVM",// Testnet",
    address: process.env.REACT_APP_CONTRACT_ADDRESS_TEVM_TOKEN_TESTNET,
    nativeCurrency: {
      name: "Telos",
      symbol: "TLOS",
      decimals: 18
    },
    blockExplorerUrls: ["https://testnet.teloscan.io"],
    rpcUrls: ["https://rpc.testnet.telos.net/"],
    endpointId: 40199, // from layerzero
    abi: abiTelos,
    backendReference: "tEVM", // what is saved on the DB on linkedAccounts
  },
  "baseSepolia": {
    chainId: 84532,
    chainName: "Base",// Sepolia Testnet",
    address: process.env.REACT_APP_CONTRACT_ADDRESS_BASE_TOKEN_TESTNET,
    nativeCurrency: {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18
    },
    blockExplorerUrls: ["https://sepolia-explorer.base.org"],
    rpcUrls: ["https://sepolia.base.org"],
    endpointId: 40245, // from layerzero
    abi: abiBase,
    backendReference: "base", // what is saved on the DB on linkedAccounts
  },
  // TODO: this, but for the mainnets ones, and below see if it's env mainnet ? use the others
  "telosEVM": {
    chainId: 40,
    chainName: "Telos EVM",
    address: process.env.REACT_APP_CONTRACT_ADDRESS_TEVM_TOKEN,
    nativeCurrency: {
      name: "Telos",
      symbol: "TLOS",
      decimals: 18
    },
    blockExplorerUrls: ["https://testnet.teloscan.io"],
    rpcUrls: ["https://rpc.testnet.telos.net/"],
    endpointId: 40199, // from layerzero
    abi: abiTelos,
    backendReference: "tEVM", // what is saved on the DB on linkedAccounts
  },
  "base": {
    chainId: 84532,
    chainName: "Base",// Sepolia Testnet",
    address: process.env.REACT_APP_CONTRACT_ADDRESS_BASE_TOKEN,
    nativeCurrency: {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18
    },
    blockExplorerUrls: ["https://sepolia-explorer.base.org"],
    rpcUrls: ["https://sepolia.base.org"],
    endpointId: 40245, // from layerzero
    abi: abiBase,
    backendReference: "base", // what is saved on the DB on linkedAccounts
  },
}

export const chains = {
  "telosEVM": _chains.telosEVMTestnet,
  "base": _chains.baseSepolia,
}

// util. function to easly use the object 'chains'
export function findByChainId(chainId) { 
  if ( ethers.utils.isHexString(chainId) )
    return Object.values(chains).find(chain => ethers.utils.hexValue(chain.chainId) === chainId) 
  return Object.values(chains).find(chain => chain.chainId === chainId) 
}

// this is used to add into metamask
function getChainsList( chainId ){
  if(!chainId) return [];
  const cL = []
  const chain = findByChainId(chainId);
  //for(const c in chains){ const chain = chains[c]
    cL.push({
      chainId: ethers.utils.hexValue(chain.chainId),
      rpcUrls: chain.rpcUrls,
      chainName: chain.chainName,
      nativeCurrency: chain.nativeCurrency,
      blockExplorerUrls: chain.blockExplorerUrls,
    })
  //}; 
  return cL;
}

export const rules = {
  "minimum_bridge_amount": 1,
  "fee": 0.2,
}

export function shortenAddress( address, chars = 4 ){
  if (!address) return '';
  address = String(address);
  return `${address.slice(0, chars + 2)}...${address.slice(-chars)}`;
}
export function maskAddress( address, chars ){
  return shortenAddress(address, chars)
}


/*
// based around 'provider'
// provider also has the following functions ''
  .waitForTransaction( transactionHash, )
  .getBalance( addressOrName )
*/
const flags = {
  switchingNetworks: true, // when switching networks
  initializingProvider: null, // await this when waiting for a new provider
}
const EthereumContext = createContext();

export const useEthereum = () => useContext(EthereumContext);

export const EthereumProvider = ({ children }) => {
  const [provider,      setProvider]      = useState(null)
  const [signer,        setSigner]        = useState(null)
  const [walletAddress, setWalletAddress] = useState(null)
  const [connected,     setConnected]     = useState(false)
  const [network,       setNetwork]       = useState(null)  // {chainId: 41, name: 'unknown'}
  const [balances,      setBalances]      = useState({})    // {41: 3821.0000}    chainId: balance

  // eslint-disable-next-line no-unused-vars
  const [afterNetworkActions, _DONOTUSEME] = useState([]) 

  const uuid = Date.now();

  useEffect(() => {
    if (!hasWallet()){console.warn("ETH Wallet not found"); return;}

    const initializeProvider = async () => {
      const _provider = new ethers.providers.Web3Provider(window.ethereum);
      
      // _provider also has 'off' and 'removeAllListeners'
      window.ethereum.removeAllListeners(); // CLEAN-UP ALL THESE 'listeners' and their old references

      setProvider(_provider)
      console.log("Initializing ETH Provider")

      try {
        window.ethereum.on("accountsChanged", () => { 
          console.log("window.ethereum.on(Account changed)"); //window.location.reload();
          flags.initializingProvider = initializeProvider();
        });
      } catch (e) {console.error(e);}

      try {
        window.ethereum.on( "chainChanged", () => { 
          console.log("window.ethereum.on(Chain changed)");
          flags.initializingProvider = initializeProvider();
        });
        _provider.on("chainChanged", () => { 
          console.log("ethereum.on('chainChanged')")
          // initializeProvider(); 
        }); 
      } catch (e) {console.error(e);}
       
      const _walletAddress = window.ethereum.selectedAddress
      if( _walletAddress ) {
        setConnected(true);
        setWalletAddress( _walletAddress );
      } else {
        setConnected(false);
        setWalletAddress(null);
      } 

      const accounts = await _provider.listAccounts()
      if(accounts.length === 0){console.log("No ETH accounts found")}
      else{
        setSigner(_provider.getSigner());

        const _network = await _provider.getNetwork();
        const _chain = findByChainId(_network.chainId);
        if(_chain)
          _network["name"] = _chain.chainName;

        //const changes = !( compareObjects( network, _network ) )
        //if( changes ) 
        setNetwork(_network)
      }
      console.log("Ethereum Context... finished. Provider initialized:", uuid);
    }

    flags.initializingProvider = initializeProvider();

    return(()=>{
      console.log("Ethereum Context... finished. Cleaning window.ethereum listeners.")
      window.ethereum.removeAllListeners();
    })

  }, []);

  useEffect(()=>{
    flags.switchingNetworks = false
    console.log("EthContext: new Network ", network);
    updateBalances( walletAddress );

    for (let i = 0; i < afterNetworkActions.length; i++) {
      const a = afterNetworkActions.shift()
      if(!(a && a.func)) continue;
      switch(a.func){
        case "addToken":
          addToken()
          .catch((e)=>{console.warn('after network action failed to add token', e)})
          break
        default:
          console.warn(`unknwon action`, a)
          break
      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[network])

  useEffect(()=>{
    if(global.isDev) console.log("new afterNetworkActions", afterNetworkActions)
  }, [afterNetworkActions])


  const hasWallet = () => {
    // Only support for MetaMask as Wallet right now
    if(typeof window.ethereum !== 'object') // should be an object, not 'undefined', not 'string' or wtv
      return false;
    return !!window.ethereum.isMetaMask;
    //return typeof window.ethereum !== 'undefined'
    //return !!window.ethereum;   // both do the same thing
  }

  // MARK: Connect Wallet
  const connectWallet = async (chainId = 0) => {
    if (provider) {
      let step = 0;
      try{
        await provider.send("eth_requestAccounts", []);     step = 1;
        const _signer = await provider.getSigner();         step = 2;
        const _walletAddress = await _signer.getAddress();  step = 3;
        setSigner(_signer);
        setWalletAddress(_walletAddress); 
        setConnected(true);
        
        // try to auto go to a desired chain
        if(chainId !== 0) await switchNetwork(chainId);           step = 4;

      } catch(e) {
        console.warn(`connectWallet (${step})- ${e}`)
      }

    }
  };

  const disconnectWallet = () => {
    // can't disconect for the user, he has to disconect it by himself on the wallet
    //window.ethereum.request({ method: "wallet_requestPermissions", params: [{ eth_accounts: {} }] }); // this doesn't work
    setSigner(null);
    setWalletAddress(null);
    setConnected(false);
  };

  // MARK: Switch Network
  const switchNetwork = async ( chainId ) => {
    if(!window.ethereum){console.error("No ETH found"); throw new Error("Tried to switch network, but couldn't find any application");}
        
    let hexChainId;

    if(! String(chainId).startsWith("0x")){
      hexChainId = ethers.utils.hexValue(chainId);
    } 

    if( network && network.chainId === chainId ){
      console.log(`Already on network: <${chainId}> === <${network.chainId}>`);
      return false
    }

    console.log(`switchNetwork - <${network ? network.chainId : '0'}> -> <${chainId}>`);

    try {
      flags.switchingNetworks = true;
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: hexChainId }],
      });
      
      while( flags.switchingNetworks ){
        await sleep(100); 
      }

      await flags.initializingProvider;

      return true;

    } catch (error) {
      if( error.code === 4902 ){ // chain not added
          console.log("Don't have that network... asking to add it + Token")

          // no need to set, we don't need the useState to update, only to be constant between chains.
          //const l = [...afterNetworkActions].push({func:'addToken',args:[chainId]})
          //setAfterNetworkActions(l)
          afterNetworkActions.push({func:'addToken',args:[chainId]})

          await addNetwork( chainId )
          .catch((e)=>{
            console.error('caught switch addNetwork', e)
          })

          console.log("finish switch network, on new network")
          
          //await switchNetwork( chainId ) // no need, it auto-switches to that network
      } else
      if( error)
      console.error("Failed to switch network:",
        error.code === 4902 ? "User rejected the switch, or not added." : error
      );      
    }
  }

  // MARK: addNetwork
  const addNetwork = async ( chainId ) => {
    const o = await window.ethereum.request({
      method: 'wallet_addEthereumChain',
      params: getChainsList( chainId ),
    }); // this errors with "h is not a function" code -32603 at r.switchChain (chrome-extension://nkbihfbeogaeaoehlefnkodbefgpgknn/background-0.js:1:358033)\n  at async chrome-extension://nkbihfbeogaeaoehlefnkodbefgpgknn/background-0.js:1:91674"
    console.log("addNetwork o", o)
  }

  // MARK: addToken
  const addToken = async ( chainId = network?.chainId ) => {
    const chain = findByChainId( chainId );
    const o = await window.ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
          options: {
            address: chain.address,
            symbol: "QUDO", // A ticker symbol or shorthand, up to 5 chars.
            decimals: 18, // The number of decimals in the token
            image: "https://games.qudo.io/android-chrome-192x192.png", // A string url of the token logo
          },
      }
    });
    console.log("addToken o", o)
  }


  /// This one is just for testing
  const sendTransaction = async (to, value) => {
    if (!signer){console.error("No signer found", signer); return;}
    let _tx;

    const call = signer.sendTransaction({
      to: to,
      value: ethers.utils.parseEther(value)
    }).then((tx) => {
      console.log("Transaction sent:", tx);
      _tx = tx;
    }).catch((error) => {
      console.error("Failed to send transaction:", error);
    });

    await call;
    return _tx;
  }

  // same chain, from current wallet, to another wallet
  // MARK: transfer
  const ethersTransfer = async (to, amount) => { // checks, validates, and sends the transaction
    if(global.isDev) console.debug(`[ethersTransfer]: to <${to}:${typeof(to)}> - amount <${amount}:${typeof(amount)}>`);
    if (!signer){console.error("[ethersTransfer]-No signer found", signer); throw new Error("No signer found");}
    console.log("Contract <Transfer> Action called");

    to = String(to);
    amount = String(getFloat(amount, 0)); // fixes to make the values compatible
    
    if( !ethers.utils.isAddress(to) )
      throw new Error(`Invalid destination address`)

    const chain = findByChainId(network.chainId)
    if(!chain){
      if(global.isDev) console.error("[ethersTransfer]-No chain found"); 
      throw new Error("Invalid contract chosen. Please contact QUDO team.");
    }
    //console.log("Chain found", chain);

    const contractCode = await provider.getCode(chain.address);
    if(!contractCode){
      if(global.isDev) console.error("[ethersTransfer]-Contract code not found"); 
      throw new Error("Contract missmatch. Please contact QUDO team.");
    }
    //console.log("Contract exists");

    const contract = new ethers.Contract(chain.address, chain.abi, signer);
    const _decimals = await contract.decimals();
    const _balance = await contract.balanceOf(walletAddress);
    const balance = ethers.utils.formatUnits(_balance, 18);

    //if(global.isDev) console.debug(`eTransfer [${chain.chainName}]: amount <${amount}> - balance <${balance}>`);
    if(Number(amount) > Number(balance)){
      if(global.isDev) console.error(`Insufficient funds: ${amount} > ${balance} `);
      throw new Error(`Insufficient funds, amount ${amount} > ${balance} available balance`);
    }

    if(Number(amount) <= 0){
      if(global.isDev) console.error(`Invalid amount <${amount}>`);
      throw new Error(`Must transfer a positive amount, not <${amount}>`);
    }
      
    if(global.isDev){
      console.log("Contract created", contract);
      console.log("Contract Name:", await contract.name());
      console.log("Contract Symbol:", await contract.symbol());
      console.log("Contract Owner:", await contract.owner());
      console.log("Contract Supply of:", await contract.totalSupply());
      console.log("Contract Decimals:", _decimals);
      console.log("Contract _balance", await _balance);
      console.log("Contract Balance", balance);
    }

    let tx;
    const withSigner = contract.connect(signer);
    try{
      tx = await withSigner.transfer(to, ethers.utils.parseUnits(amount, _decimals));
    } catch (e) {
      console.error("[ethersTransfer]-Contract Action error", e);
      console.error(JSON.stringify(e));
      throw new Error("Transaction failed");
    }
    console.log("[ethersTransfer]-Contract Action tx", tx);

    return tx;
  }


  // MARK: permissionToBridgeBack
  const permissionToBridgeBack = async ( amount ) => {

    amount = String(getFloat(amount, 0)); // fixes to make the values compatible

    if( network.chainId !== chains.telosEVM.chainId ) {
      console.error(`[permissionToBridgeBack] - Not compatible with chain <${network.chainId}:${network.name}>`);
      //throw new Error(`permissionToBridgeBack Not implemented for the chain <${network.chainId}:${network.name}>. Only for <${chains.telosEVM.chainId}:${chains.telosEVM.chainName}>`);
    }
    if (!signer){
      console.error("[permissionToBridgeBack] - No signer found", signer);
      throw new Error("No signer found");
    }

    const to = process.env.REACT_APP_CONTRACT_ADDRESS_TEVM_BRIDGE_TESTNET
    const chain = findByChainId(network.chainId)
    console.debug(`[permissionToBridgeBack]: to <${to}:${typeof(to)}> - amount <${amount}:${typeof(amount)}>`);

    if(!chain){
      console.error("[ethersTransfer] - No chain found"); 
      throw new Error("Invalid contract chosen. Please contact QUDO team.");
    }

    const contractCode = await provider.getCode(chain.address);
    if(!contractCode){
      console.error("[ethersTransfer] - Contract code not found"); 
      throw new Error("Contract missmatch. Please contact QUDO team.");
    }

    // just for check later
    const bridgeContract = new ethers.Contract(addressBridge, abiBridge, signer);
    rules.minimum_bridge_amount = parseFloat( ethers.utils.formatUnits( await bridgeContract.min_amount(), 18) );
    rules.fee = Number( ethers.utils.formatUnits( await bridgeContract.fee(), 18) );

    if( Number(amount) < rules.minimum_bridge_amount ){
      throw new Error(`Failed, minimum amount to bridge is ${rules.minimum_bridge_amount} QUDO`)
    }

    const contract = new ethers.Contract(chain.address, chain.abi, signer);
    const _decimals = await contract.decimals();
    const _balance = await contract.balanceOf(walletAddress);
    const balance = ethers.utils.formatUnits(_balance, _decimals); // without the 0'z, decimal (parseUnits adds the 0)

    if( Number(amount) > Number(balance) ){
      if(global.isDev) console.error(`Insufficient funds: ${amount} > ${balance}`);
      throw new Error(`Insufficient funds, amount ${amount} > ${balance} available balance`);
    }

    if( Number(amount) <= 0 ){
      if(global.isDev) console.error(`Invalid amount <${amount}>`);
      throw new Error(`Must transfer a positive amount, not <${amount}>`);
    }

    // TODO: confirm current amount permited to bridge, if enough, ok, else, do the difference
    const _allowance = await contract.allowance(walletAddress, addressBridge) // with all the 0'z
    const allowance = ethers.utils.formatUnits(_allowance, _decimals)

    // false to disable this... allowance overwrites the value currently there - Good practice to keep things tight.
    if(false)
    if( Number(allowance) > Number(amount) ){
      console.error(`Enough allowance: ${allowance} > ${amount}`);
      return "1"
    } 
    else if( Number(allowance) > 0 ){ 
      amount = String( Number(amount) - Number(allowance) )
    } // keeping it here just for memory of the past

    let tx;
    const withSigner = contract.connect(signer);

    try{
      tx = await withSigner.approve(to, ethers.utils.parseUnits(String(amount), _decimals));
      // await tx.wait(); // we don't need to wait here , when the user signs the other side, it should have been processed already
    } catch (e) {
      console.error("[permissionToBridgeBack] - Contract Action error", e);
      if( e.code === "ACTION_REJECTED" ){
        throw new Error("User canceled transaction")
      }
      throw new Error(e.reason);
    }
    console.log("[permissionToBridgeBack]-Contract Action tx", tx);

    return tx;
  }

  // meaning, bridge to Telos ZERO from tEVM
  // MARK: BridgeBack
  const ethersBridgeBack = async ( amount, receiver ) => {

    amount    = String(getFloat(amount, 0)); // fixes to make the values compatible
    receiver  = String(receiver); // TODO: check if is a valid address

    const data          = await QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/api/isvalidaccname?accname=${receiver}`)
    const accountExists = data.data;
    if(!accountExists)
      throw new Error("Invalid, receiver, couldn't find the function")

    if( network.chainId !== chains.telosEVM.chainId ) {
      console.error(`[ethersBridgeBack]-Not compatible with chain <${network.chainId}:${network.name}>`);
      throw new Error(`ethersBridgeBack Not implemented for the chain <${network.chainId}:${network.name}>. Only for <${chains.telosEVM.chainId}:${chains.telosEVM.chainName}>`);
    }

    if (!signer){
      console.error("[ethersBridgeBack]-No signer found", signer);
      throw new Error("No signer found");
    }
    
    const contractCode = await provider.getCode(addressBridge);
    if(!contractCode){
      console.error("[ethersBridgeBack]-Contract code not found"); 
      throw new Error("Contract missmatch. Please contact QUDO team.");
    }
    
    const chain = findByChainId(network.chainId)
    if(!chain){
      console.error("[ethersBridgeBack]-No chain found"); 
      throw new Error("Invalid contract chosen. Please contact QUDO team.");
    }

    const bridgeContract  = new   ethers.Contract(addressBridge, abiBridge, signer);
    const tokenContract   = new   ethers.Contract(chain.address, chain.abi, signer);
    const _decimals       = await tokenContract.decimals();
    const _balance        = await tokenContract.balanceOf(walletAddress);
    const userBalance     =       ethers.utils.formatUnits(_balance, 18);
    const _allowance      = await tokenContract.allowance(walletAddress, addressBridge) // with all the 0'z
    const allowance       = ethers.utils.formatUnits(_allowance, _decimals)

    if(Number(amount) > Number(userBalance)){
      console.error(`Insufficient funds: ${amount} > ${userBalance}`);
      throw new Error(`Insufficient funds, amount ${amount} > ${userBalance} available balance`);
    }
    if(Number(amount) <= 0){
      console.error(`Invalid amount <${amount}>`);
      throw new Error(`Must transfer a positive amount, not <${amount}>`);
    }

    console.debug(`[ethersBridgeBack]: amount <${amount}> - balance <${userBalance}> - allowance <${allowance}>`);
      
    if(global.isDev){
      console.log("Contract created", bridgeContract);
      console.log(" - User Balance:", userBalance);
      console.log(" -     Decimals:", _decimals);
    }

    let tx;
    const withSigner = bridgeContract.connect(signer);
    const coverFee = ethers.utils.parseUnits( String(rules.fee), chain.nativeCurrency.decimals ); 
    const transactionConfig = {
      value: coverFee,
    };
    
    try{
      //tx = await withSigner.bridge(chain.address, ethers.utils.parseUnits(amount, _decimals), receiver);
      tx = await withSigner.bridge(chain.address, ethers.utils.parseUnits(amount, _decimals), receiver, transactionConfig);
    } catch (e) {
      console.error("[ethersBridgeBack] - Contract Action error", e);
      const reason = String(e.reason).split("revert: ");
      if( reason.length>0 ){
        if( reason[1] === "Minimum amount is not reached" ){
          throw new Error(`Reverted, minimum amount to bridge is ${rules.minimum_bridge_amount} QUDO`);
        }
        throw new Error(`Transaction reverted becase "${reason[1]}"`);
      } 
      if( e.code === "ACTION_REJECTED" ) throw new Error("User canceled transaction")
      throw new Error(e.reason);
    }
    console.log("[ethersBridgeBack]-Contract Action tx", tx);

    return tx;
  }

  // from current chain, to another chain
  // MARK: bridge
  const ethersBridge = async (_to, _amount, _chain) => {
    _to     = String(_to); // fixes to make the values compatible
    _amount = String(getFloat(_amount, 0)); 

    if (!signer){
      console.error("No signer found", signer)
      return
    }

    console.debug(`[ethersBridge]: to <${_to}:${typeof(_to)}> - amount <${_amount}:${typeof(_amount)}> - chain <${_chain}:${typeof(_chain)}>`)

    const chain = findByChainId(network.chainId);
    if(!chain){
      console.error("[ethersBridge]-No chain found"); 
      throw new Error("Invalid contract chosen. Please contact QUDO team.");
    }

    const otherChain = findByChainId(getNumber(_chain));
    if(!otherChain){
      console.error(`[ethersBridge]-No other chain found <${_chain}>`); 
      throw new Error("Invalid chain chosen. Please contact QUDO team.");
    }

    if(!ethers.utils.isAddress(_to)){
      console.error(`[ethersBridge]-Invalid address <${_to}>`); 
      throw new Error("Invalid address to bridge");
    }
    // _ to is 0x
    const to = ethers.utils.getAddress(_to);
    
    const contract  = new   ethers.Contract(chain.address, chain.abi, signer);
    const _decimals = await contract.decimals();
    const balance   = await contract.balanceOf(walletAddress);            // with decimals (lot of 0's)
    const amount    =       ethers.utils.parseUnits(_amount, _decimals);  // with decimals (lot of 0's)
    const _balance  =       ethers.utils.formatUnits(balance, 18);        // without decimals (removes zeros)
    
    if(Number(_amount) > Number(_balance)){
      console.error(`Insufficient funds <${_amount}> > <${_balance}>`);
      throw new Error(`Insufficient funds, amount ${_amount} > ${_balance} available balance`);
    }

    const withSigner = contract.connect(signer);

    console.debug(`Sending from <${chain.chainName}> to <${otherChain.chainName}>\nAmount <${_amount}>,<${_decimals}>\nTo <${to}>`);

    // from sendOFT call
    let options = "0x00030100110100000000000000000000000000030d40";

    const sendParam = [
      otherChain.endpointId,
      ethers.utils.hexZeroPad(to, 32),
      amount,
      amount.mul(98).div(100),
      options,
      "0x",
      "0x"
    ];
    const feeParam    = [0, 0]; // this is filled by quoteSend
    const quoteSend   = await contract.quoteSend(sendParam, false) // make a estimation of the OFT send cost
    console.log("QuoteSend", quoteSend)
    console.log("quoteSend[0]", quoteSend[0])

    // from other calls ( ...n   to convert to BigInt) // came from the /oft/qudo-oft test project, can't replicate here so hard coded it
    // Is from the quoteSend
    let nativeFee = 0;  // TODO use the sendQuote to figure this out, don't use hard coded values
    switch (network.chainId) {
      case chains.base.chainId: // curr balance of 0.29986 ETH
        nativeFee = 1000000000000000n // 0.001
        nativeFee = 500000000000n // 0.0000005
        // 0.0000003 should be more than enough
        break;
      case chains.telosEVM.chainId:
        nativeFee = 747903338229245522n // 0.7479 TLOS ( from examples )
        nativeFee = 750000000000000000n // 0.7500 TLOS ( prettier / readable )
        break;
      default:
        nativeFee = quoteSend[0]
        break;
    }
    nativeFee               = quoteSend[0]      // forcing this, the above is useless currently
    const overkillNativeFee = nativeFee.mul(2);
    feeParam[0]             = nativeFee;

    const gasPrice      = await provider.getGasPrice();
    //const estimateGas   =       contract.estimateGas.send(sendParam, feeParam, walletAddress)

    const nonce = await provider.getTransactionCount(walletAddress);
    
    if(global.isDev){
      console.log("Native Fee", nativeFee);
      console.log("overkillNativeFee", overkillNativeFee);
      console.log("Gas Price", gasPrice);
      //console.log("Estimated Gas", estimateGas);
      console.log("Nonce", nonce);
    }


    let gasLimit = 0
    switch (network.chainId) { // This we have to define by hand
      case chains.base.chainId:
        gasLimit = 400000n // 240,000 should be enough
        break;
      case chains.telosEVM.chainId:
        gasLimit = 400000n // usually only is 223.000, better have some leverage
        break;
      default:
        gasLimit = await contract.estimateGas.send(sendParam, feeParam, walletAddress) // if not done, atleast try to get the estimate, will probably fail
        break;
    }

    try{
      const tx = await withSigner.send(sendParam, feeParam, walletAddress,{
        value: nativeFee,
        gasPrice: gasPrice,
        nonce: nonce,
        gasLimit: ethers.utils.hexlify(gasLimit),
      });
      console.log("[ethersBridge]-Contract Action tx", tx);

      await tx.wait();
      console.log("[ethersBridge]-Contract Action tx terminated. Bridge Done");

      return tx;
    } catch (e) {
      console.error("[ethersBridge] - Contract Action error", e);
      if( e.code === "ACTION_REJECTED" ){
        throw new Error("User canceled transaction")
      }
      throw new Error(e.reason);
    }    
  }

  // MARK: Balance
  const getBalance = async () =>{
    if(!signer) {console.warn("getBalance called without signer ... not possible"); return null;}
    getBalanceOf(walletAddress, network.chainId);
  }

  const getNativeBalance = async ( address = walletAddress ) => {
    if (!provider) {
      console.warn("getNativeBalance called without provider ... not possible");
      return null;
    }

    try {
      const balance = await provider.getBalance(address); // Get balance in wei (smallest unit)
      const formattedBalance = ethers.utils.formatEther(balance); // Convert wei to ether (or native currency)
      console.debug(`getNativeBalance - ${formattedBalance} ${findByChainId(network.chainId).nativeCurrency.symbol} in address ${address}`)
      return formattedBalance; // Return the balance in a human-readable format
    } catch (e) {
      console.error(`Failed to get native balance for address <${address}>:`, e);
      return null;
    }
  }

  const getBridgerNativeBalance = async () => {
    if ( !addressBridger ) {
      console.warn("Bridger address is not defined... check the .env !");
      return null;
    }

    if( !network || network.chainId !== chains.telosEVM.chainId ){
      console.warn(`bridgerNativeBalance - Not compatible with chain <${network.chainId}:${network.name}>`);
      return null;
    }
  
    return await getNativeBalance( addressBridger );
  };

  // MARK: getBalanceOf
  const getBalanceOf = async ( address, chainId ) => {
    if(!signer) {console.warn("getBalanceOf called without signer ... not possible"); return null;}

    const chain = findByChainId(chainId);
    if(!chain){ console.warn(`Couldn't get balanceOf <${address}> on chainId <${chainId}>, I don't know such chain`); return null; }
    // the user needs to be on this network currently btw
    const onSameNetwork = chain.chainId === network.chainId;
    if(onSameNetwork){
      try{
        const contract = new ethers.Contract(chain.address, chain.abi, signer);
        const _balance = await contract.balanceOf(address);
        const _decimals = await contract.decimals();

        const balance = ethers.utils.formatUnits(_balance, _decimals);

        //if(global.isDev) console.log(`balance <${balance}> _decimals <${_decimals}> _balance <${_balance}>`);
        
        return balance
      } catch (e) {
        console.warn(`getBalanceOf - address <${address}> chainId <${chainId}> - ${e}`)
        return null;
      }
    } else {
      console.warn("I don't know how to gather the balance without having the wallet on that chain.");
      if(address === walletAddress){
        return balances[chainId]; // just return the balance we have
      }
      return null;
    }
  }

  // MARK: getBalancesOf
  const getBalancesOf = async (address) => {
    const _balances = ( address === walletAddress ? {...balances} : {} ); 
    const currentNetwork = network;       if(!currentNetwork) return _balances;
    
    _balances[currentNetwork.chainId] = await getBalanceOf(address, currentNetwork.chainId);
    balances[currentNetwork.chainId] = _balances[currentNetwork.chainId]
    return _balances;
  }

   // MARK: updateBalances
  let updateBalancesRunning = false 
  const updateBalances = (address = walletAddress) => {
    if(updateBalancesRunning) return;
    if(!network || typeof network !== "object") return;

    updateBalancesRunning = true;

    const beforeUpdate = {...balances} // we need a copy, as we modify the balances object itself also
    
    let _output = null;
    getBalancesOf(address).then((b) => {
      if(global.isDev){ 
        console.debug(`updateBalances on <${address}> at chain <${network.chainId}>`);
      } 
      
      // to not force update unecesserly
      const changes = !( compareObjects( b, beforeUpdate ) )
      if( changes ){
        setBalances(b);
        if(global.isDev){ 
          console.debug("updateBalances before", beforeUpdate);
          console.debug("updateBalances after", b);
        } 
      }
        
      _output = b; // return the object just in case
    }).catch((e) => {
      console.error("updateBalances catch", e);
    }).finally(() => {
      updateBalancesRunning = false;
      return _output;
    });
  }

  // MARK: end
  return (
    <EthereumContext.Provider value={{
      hasWallet,  // Has Metamask or any other software for browserWallet ?
      provider,
      signer,
      walletAddress,
      balances,
      network,
      switchNetwork,
      connected,  // is connected or not
      connectWallet,
      disconnectWallet,
      sendTransaction,  // this is for test... USE THE ONES BELOW ( ethersTransfer and ethersBridge )
      ethersTransfer, // transfer within that chain
      ethersBridge, // bridge between chains
      permissionToBridgeBack, // grants the bridge permission to use your tokens - needed for <ethersBridgeBack>
      ethersBridgeBack, // bridge back to the original chain
      getBalance, // simple version of the getBalanceOf
      getBalanceOf, // address, chainId
      updateBalances,
      getNativeBalance, // for the TLOS/Ether
      getBridgerNativeBalance, // same as above, but the Bridger contract
      addToken, // add the QUDO token to the MetaMask
  }}>
      {children}
    </EthereumContext.Provider>
  );
};

// --------------------------------------------------------
// --------------------------------------------------------
/* USAGE EXAMPLE

import React from 'react';
import { useEthereum } from '../helpers/EthereumContext';

const Test = () => {
  const { connected, walletAddress, connectWallet, sendTransaction, switchNetwork, ethersTransfer, ethersBridge, network } = useEthereum();
  
  if(!connected){
    return (
      <div>
        <h1>Connect Wallet</h1>
        <button onClick={connectWallet}>Connect Wallet</button>
      </div>
    );
  } 

  return (
    <div>
      <div>
        <p>Connected to {walletAddress}</p>
        <button onClick={disconnectWallet}>Disconnect Wallet</button>
      </div>
    </div>
  );
};

export default Test;

*/