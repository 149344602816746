import React from 'react';
import cssColors from 'helpers/cssColors.js';

import { ethers } from 'ethers';
import { shortenAddress } from 'helpers/EthereumContext';

export default function WalletConnectionButton ({
    title, address, connected, amount,
    color           = cssColors.BLACK,  // Main element color (border/background)
    btn_textColor   = cssColors.WHITE,  // button text color    (contract with color)   (easier)
    uncBtn_Text     = "CONNECT",        // unconnected button
    uncBtn_onClick  = ()=>{return},     // unconnected button onClick function
    uncBtn_style    = {},               // unconnected button style
    uncBtn_class    = "",               // unconnected button className
    conBtn_Text     = "OPEN",           // connected button text
    conBtn_onClick  = ()=>{return},     // connected button onClick function
    conBtn_style    = {},               // connected button style
    conBtn_class    = "",               // connected button className
    style           = {},               // overall style
    className       = "",               // overall className
}) {

    const isMobile = ( window && window.innerWidth < 768);
    
    const isAddress = ethers.utils.isAddress(address); // if is Ethers Address vs TLOS address (31 chars vs 12 chars)

    return(
        <div style={style} className={className}>
            { (title || address) &&     // NO title and NO address  ===  NO field here 
            <div style={{display:"flex", gap:"8px", margin:"6px 0px 2px", flexWrap:'nowrap', justifyContent:isAddress?'space-between':''}}>
                <span style={{
                    fontSize:"12px", color:cssColors.QUDO_DARK_GREY,
                    lineHeight:"19.2px"
                }}>
                    {title}
                </span>
                
                {address && 
                <span className='wallet-username-text-small' style={{
                   fontSize:"14px", color:cssColors.QUDO_MEDIUM_GREY
                }}>{ 
                    isMobile && isAddress
                    ? shortenAddress(address)
                    : address
                }</span>
                }
            </div>
            }

            { connected 
            ?      //MARK: CONNECTED
            <div 
                style={{ ...{
                    height:"64px",
                    borderRadius:"8px",
                    border:`1px solid ${color}`,
                    display:"flex",
                    justifyContent:"space-between",
                }, ...conBtn_style}}
            >
                <div style={{
                    display:"flex",
                    flexDirection:"column",
                    alignItems:"flex-end",
                    justifyContent:"center",
                    paddingLeft:"24px",
                }}>
                    <span style={{fontSize:"20px"}}>{amount}</span>
                    <span style={{fontSize:"12px", color:"#707070"}}>Qudo</span>
                </div>
                <button 
                    style={{ ...{
                        backgroundColor: color,
                        color: btn_textColor,
                        width: "140px",
                        border: "0",
                        borderRadius:"0px 8px 8px 0px"
                    }, ...conBtn_style}} 
                    onClick={conBtn_onClick}
                    className={conBtn_class}
                > 
                    {
                        conBtn_Text
                    }
                </button>

            </div>

            :    //MARK: UNCONNECTED
            <button
                style={{...{
                    height:"64px",
                    borderRadius:"8px",
                    backgroundColor:color,
                    border:"0",
                    color: btn_textColor,
                    width: "100%"
                }, ...uncBtn_style}}
                onClick={uncBtn_onClick}
                className={uncBtn_class}
            >    
                {
                    uncBtn_Text
                }
            </button>
            }
        </div>
    )

}