import React, { useEffect, useState }                        from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setWalletPage }            from 'actions';

import cssColors                from 'helpers/cssColors.js';
import { useEthereum, chains }  from 'helpers/EthereumContext.js' 
import { pages }                from 'components/WalletMultichain';
import WalletConnectionButton   from './ConnectionButton'
import { compareObjects } from 'helpers/utils';


export default function WalletConnection ({ balance }) {
    const dispatch  = useDispatch();
    const eth       = useEthereum(); 

    const ual   = useSelector(state => state.ual);
    const info  = useSelector(state => state.info);
    const [lk, setLk] = useState({})
    const [tEVMAddress, settEVMAddress] = useState(undefined)
    const [baseAddress, setBaseAddress] = useState(undefined)

    const anchorConnected = !(!info.qudoManaged && !ual.activeUser);

    useEffect(()=>{
        const _lk = info.linkedAccounts || {}
        if( compareObjects(lk, _lk) ){
            setLk(_lk)
        }
        settEVMAddress(_lk[chains.telosEVM.backendReference])
        setBaseAddress(_lk[chains.base.backendReference])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ info ])


    const eth_handleConnectWallet = (chainId) => {
        if( ! eth.hasWallet() ){
            window.open('https://metamask.io/download.html', '_blank');
            return;
        } 
        eth.connectWallet(chainId)
    };

    const eth_uncBtn_Text = () => {
        return eth.hasWallet() ? "CONNECT" : "GET METAMASK";
    }

    const eth_amount = (chainId) => {
        const a = eth.balances[chainId]
        if(a === undefined){
            return (<span style={{display:'block',fontSize:'14px',marginRight:'-56px'}}>open to load</span>); // haven't looked for it
        }
        if(a === null){
            eth.updateBalances()
            return (<span style={{display:'block',fontSize:'14px',marginRight:'-76px'}}>could not find</span>); // updateBalances makes it null in error (can prob have -1 for error)
        } 
        return a;
    }
    
    return(
        <div style={{display:"flex", flexDirection:"column", background:"white"}}>
            <span style={{ padding:"32px 27px",
                color:cssColors.QUDO_MEDIUM_GREY, fontSize:"18px"
            }}>
                Multi-chain Allocation
            </span>
            <div style={{ gap:"16px", padding:"0px 30px 32px" }}>
                <WalletConnectionButton title={"Telos zero"} color={cssColors.QUDO_YELLOW}
                    connected={anchorConnected}
                    address={info.account_name}
                    amount={balance}
                    btn_textColor={cssColors.BLACK}
                    uncBtn_onClick={() => { ual.logout(); ual.restart(); ual.showModal(); }}
                    conBtn_onClick={() => { dispatch(setWalletPage(pages.ZERO.id)) }}
                />
                <WalletConnectionButton title={"Telos EVM"} color={cssColors.QUDO_BLUE}
                    connected={eth.connected}
                    address={tEVMAddress}
                    amount={eth_amount(chains.telosEVM.chainId)}
                    uncBtn_Text={ eth_uncBtn_Text() }
                    uncBtn_onClick={() => { eth_handleConnectWallet(chains.telosEVM.chainId) }}
                    conBtn_onClick={() => { dispatch(setWalletPage(pages.TEVM.id)) }}
                />
                <WalletConnectionButton title={"Base"} color={cssColors.QUDO_DARK_GREY}
                    connected={eth.connected}
                    address={baseAddress}
                    amount={eth_amount(chains.base.chainId)}
                    uncBtn_Text={ eth_uncBtn_Text() }
                    uncBtn_onClick={() => { eth_handleConnectWallet(chains.base.chainId) }}
                    conBtn_onClick={() => { dispatch(setWalletPage(pages.BASE.id)) }}
                />
            </div>
        </div>
    )    
}

