import React, { useRef, useState, useEffect } from "react"
import { useSelector } 		from "react-redux"
import { Form } 			from "react-bootstrap"
import ReactTooltip 		from 'react-tooltip'
import { ClipLoader } 		from 'react-spinners'
import { arrayToHex } 		from "eosjs/dist/eosjs-serialize"

import { useEthereum, shortenAddress, findByChainId, chains } from 'helpers/EthereumContext.js' 
import * as MK 				from 'helpers/metakeep.js'
import useDebounce 			from 'helpers/useDebounce'
import QUDOServer 			from 'helpers/QUDOServerConnection'

import { MessageModal } 	from "components/components/modals/modals.js"
import ReferralModal 		from "components/components/modals/referralModal/referralModal"
import StakesComponent 		from "components/common/stakes/stakesComponent"
import BalanceLimitCard 	from "components/common/balanceLimit/balanceLimitCard"
import WalletMigration 		from "components/common/walletMigration/walletMigration"
import WalletImportTutorial from "components/common/walletMigration/walletImportTutorial"
import WalletMigrationCard 	from "components/common/walletMigration/walletMigrationCard.js"

import Transfer 			from "components/walletMultichain/Transfer.js"
import { eligleForMetakeep } from "helpers/betasEliglebles"
import { confirmAmount } from "helpers/utils"

const send = require("../../images/definitive/icn-send-qudos.png");
const arrow = require("../../images/definitive/icn-arrow-forward.png");

// 
export default function Send({
	update 					= () => { },	// Function to update the balance values
	showAccountName 		= false, 		// Show the account name on top of the component
	showStakeComponent		= false,		// Show the stake component
	showReferralComponent	= false,		// Show the referral component
	chainId					= 0,			// Chain ID for the balances / operations (only for ethers)
	detailColor,							// Main Color for details
}) {
	//Although not being used, this next statement forces the component to rerender whenever the window size changes (so don't delete)
	const isMobile = ( window && window.innerWidth < 768);

	const eth = useEthereum();
	const isEVMPage = chainId !== 0; // If chainId is 0, then it's TELOS Zero, otherwise it's an EVM chain
	const chain = findByChainId(chainId || chains.telosEVM.chainId)

	const form = useRef(null);

	const ual = useSelector(state => state.ual);
	const balance = useSelector(state => state.balance);
	const currentUser = useSelector(state => state.info);

	//const [to, setTo] = useState('');
	const [searchTerm, setSearchTerm] = useState('');
	const to = useDebounce(searchTerm, 250);
	const [walletAddress, setWalletAddress] = useState(null)

	const [quantity, setQuantity] = useState(0);
	const [maxBalance, setMaxBalance] = useState(0);
	const [searchByAccName, setSearchByAccName] = useState(false);
	const [receiverInfo, setReceiverInfo] = useState({});
	const [avatarLink, setAvatarLink] = useState(`${process.env.REACT_APP_QUDO_SERVER}/api/avatars/getbyid?imageID=0&type=user`);
	const [errorMessage, setErrorMessage] = useState('');

	//const [lockSend, setLockSend] = useState(false);
	const [sendLoading, setSendLoading] = useState(false);
	//const [confirmSendMessage, setConfirmSendMessage] = useState('');

	const [modalMessage, setModalMessage] = useState('');
    const [successModal, setSuccessModal] = useState(false);
	const [failureModal, setFailureModal] = useState(false);

    const [referralModal, setReferralModal] = useState(false);
	const [tutorialModal, setTutorialModal] = useState(false);
    const [migrationModal, setMigrationModal] = useState(false);

	useEffect(() => {
		if(balance && balance.balance) {
			if(balance.stake) {
				const balanceValue = Number(String(balance.balance).split(' ')[0]);
				const stakeValue = Number(String(balance.stake).split(' ')[0]);

				setMaxBalance(balanceValue - stakeValue);
			} else {
				const balanceValue = Number(String(balance.balance).split(' ')[0]);

				setMaxBalance(balanceValue);
			}
		}
	}, [balance]);

	useEffect(() => {
		const lk = currentUser.linkedAccounts || {}
		const to = lk[chain.backendReference]
		setWalletAddress(to)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ currentUser ])

	useEffect(() => {
		if(to) {
			var link = `${process.env.REACT_APP_QUDO_SERVER}/api/whois/user`;

			if(searchByAccName) // accName = jnaike1.qudo 	or		0x...41d
				{ link += `?account_name=${to}` }
			else{ link += `?username=${to}` }

			if(isEVMPage) 		// backend knows: [ tEVM, base ]
				link += `&linked_accounts=${chain.backendReference}`

			QUDOServer.get(link)
			.then((data) => {
				setReceiverInfo(data.data);
				setErrorMessage('');
			})
			.catch((err) => {
				if( isEVMPage ){
					setReceiverInfo({});
					if( err.errorMessage ){
						if( err.errorMessage === 'User not found'){
							setErrorMessage('User not found in the QUDO ecosystem!')
						} else 
						if (String(err.errorMessage).includes('chain')){
							setErrorMessage(String(err.errorMessage).replace("chain", chain.chainName))
						} else{
							setErrorMessage('Account not found in the QUDO ecosystem!')
						}
					} else {
						console.warn(err)
						setErrorMessage('Account not found in the QUDO ecosystem!')
					}
				} else 
				
				if(searchByAccName) {
					QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/api/isvalidaccname?accname=${to}`)
					.then((data) => {
						console.log(data.data)
						const accountExists = data.data;
						if(accountExists) {
							setReceiverInfo({});
							setErrorMessage('User not found in the QUDO ecosystem but TELOS account does exist!');
						} else {
							setReceiverInfo({});
							setErrorMessage('User not found in the QUDO ecosystem and TELOS account does not exist!');
						}
					})
					.catch((error) => {
						setReceiverInfo({});
						setErrorMessage(error.errorMessage);
					});
				} else {
					setReceiverInfo({});

					if(err.errorMessage && err.errorMessage !== 'User not found') {
						setErrorMessage(err.errorMessage);
					} else {
						setErrorMessage('User not found in the QUDO ecosystem!');
					}
				}
			});
		} else {
			setErrorMessage('')
			if(Object.keys(receiverInfo).length !== 0){
				setReceiverInfo({});
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [to, searchByAccName]);

	useEffect(() => {
		const type = receiverInfo.type ? receiverInfo.type : '';
		const imageID = receiverInfo.imageID ? receiverInfo.imageID : 0;

		setAvatarLink(`${process.env.REACT_APP_QUDO_SERVER}/api/avatars/getbyid?imageID=${imageID}&type=${type}`);
	}, [receiverInfo]);

	// MARK: EVM
	async function submitEVM(){
		if(chainId !== eth.network.chainId){
			console.error(`Send submit: Chain ID mismatch "selected <${chainId}>" != "Wallet <${eth.network.chainId}>" `);
			setErrorMessage(`Wallet was on the wrong network. Try again`)
			await eth.switchNetwork( chainId ) 
			return
		}
		// Making sure the user is RLY on the correct Network
		// await eth.switchNetwork( chainId ) 
		// TODO this ^ doesn't work! need an -> const newNetwork = useState([]); 
		// 						+ an -> useEffect (()=>{...},[eth.network]);
		// here, would write on the list -> newNetwork[0].push( {func: "ethersTransfer", args[toWallet, quantity]} )
		// then switch the network, which should trigger the useEffect
		// and there is just like :
		// -- inspired of a mini version of what Transfer.js does
		// -- or copy almost the same as on EtheureumContext.js

		const toWallet = searchByAccName ? to : receiverInfo?.linkedAccounts[chain.backendReference]
		
		try {
			await eth.ethersTransfer(toWallet, quantity)
			.then(( tx )=>{
				update();
				setModalMessage("Success");
				setSuccessModal(true);
			})
			.catch((error) => {
				console.error("Send submit error", error);
				setModalMessage(error.message);
				setFailureModal(true);
			});
		} catch (e) {
			console.error("Send submit error", e);
			setModalMessage(e.message);
			setFailureModal(true);
		} finally {
			setSendLoading(false);
			return;
		}
	}

	// MARK: TELOS
	async function submitTelos(){
		var receiver = '';

		if(receiverInfo && receiverInfo.account_name) {
			receiver = receiverInfo.account_name;
		} else {
			if(searchByAccName) {
				receiver = to;
			} else {
				receiver = '';
			}
		}

		try {
			var accountNameValid = await QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/api/isvalidaccname?accname=${receiver}`);

			if(!accountNameValid) {
				const errorMessage = {
					code : 404,
					message : ""
				}

				throw errorMessage;
			}
		} catch(error) {
			setModalMessage("Account not found!");
			setFailureModal(true);
			setSendLoading(false);
			return;
		}

		if(currentUser.qudoManaged){
			if(process.env.REACT_APP_METAKEEP_RELEASED === 'true' || eligleForMetakeep(currentUser) ){
				const account = process.env.REACT_APP_TOKENCONTRACT;
				const actions = [{
					"account": account,
					"name": "transfer",
					"authorization": [{
						"actor": currentUser.account_name,
						"permission": "active"
					}],
					"data": {
						from: currentUser.account_name,
						to: receiver,
						quantity: `${Number(quantity).toFixed(4)} ${process.env.REACT_APP_TOKENNAME}`,
						memo: "Transfer"
					}
				}];

				MK.signTransaction(currentUser.email, actions, {broadcast: false})
				.then((signResponse) => {
					QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/eos/send`, {
						from: currentUser.account_name,
						to: receiver,
						to_username: receiverInfo ? receiverInfo.username : null,
						quantity: quantity,
						memo: "Transfer",
						signature: signResponse.unpackedTransaction,
					}, {
						withCredentials: true
					})
					.then((result) => {
						update();
						setModalMessage("Success");
						setSuccessModal(true);
					})
					.catch((error) => {
						setModalMessage(error.errorMessage);
						setFailureModal(true);
					})
					.finally(() => {
						setSendLoading(false);
					});
					// console.log("sign successful", signResponse);
					// update();
					// setModalMessage("Success");
					// setSuccessModal(true);
				})
				.catch((error) => {
					console.log("Metakeep send error on account", MK.getEOS(), error);
					setModalMessage(error.message);
					setFailureModal(true);
				})
				.finally(() => {
					setSendLoading(false);
				});
			} 
			
			else
			
			{ // Previous version, without metakeep // TODO: DELETE WHEN METAKEEP FULLY RELEASED
				QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/eos/send`, {
					from: currentUser.account_name,
					to: receiver,
					to_username: receiverInfo ? receiverInfo.username : null,
					quantity: quantity,
					memo: "Transfer",
					signature: null,
				}, {
					withCredentials: true
				})
				.then((result) => {
					update();
					setModalMessage("Success");
					setSuccessModal(true);
				})
				.catch((error) => {
					setModalMessage(error.errorMessage);
					setFailureModal(true);
				})
				.finally(() => {
					//setLockSend(false);
					setSendLoading(false);
					//setConfirmSendMessage('');
				});
			}
		}

		else

		if(!currentUser.qudoManaged) {
			const signedData = await ual.activeUser.signTransaction({
				actions: [{
					account: process.env.REACT_APP_TOKENCONTRACT,
					name: "transfer",
					authorization: [{
						actor: currentUser.account_name,
						permission: "active"
					}],
					data: {
						from: currentUser.account_name,
						to: receiver,
						quantity: `${Number(quantity).toFixed(4)} ${process.env.REACT_APP_TOKENNAME}`,
						memo: "Transfer"
					}
				}]
			}, {
				blocksBehind: 3,
				expireSeconds: 30,
				broadcast: false
			});

			const signatureData = {
				signatures: signedData.transaction.signatures,
				packed_trx: arrayToHex(signedData.transaction.serializedTransaction)
			}
			console.log("ANCHOR signed", signedData)
			console.log("ANCHOR sD",signatureData)
			QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/eos/send`, {
				from: currentUser.account_name,
				to: receiver,
				to_username: receiverInfo ? receiverInfo.username : null,
				quantity: quantity,
				memo: "Transfer",
				signature: signatureData
			}, {
				withCredentials: true
			})
			.then((result) => {
				update();
				setModalMessage("Success");
				setSuccessModal(true);
			})
			.catch((error) => {
				setModalMessage(error.errorMessage);
				setFailureModal(true);
			})
			.finally(() => {
				setSendLoading(false);
			});
		}
	}

	// MARK: submit
	async function submit() {
		setSendLoading(true);
		
		if(isEVMPage) {
			await submitEVM()
		} else {
			await submitTelos()
		}
	}

	return (
		<>
			<Form ref={form}>
				{showAccountName && 
				<div className="mb-3 ml-1"
					style={{fontSize: '1.25rem'}}
				>
					Your TELOS account name is

					<div
						className="d-inline-block enable-click ml-1"
						style={{fontWeight: "bold", textDecoration: "underline"}}
						onClick={() => {
							window.open(`https://eosauthority.com/account/${currentUser.account_name}?network=telostest`, '_blank');
						}}
					>
						{currentUser.account_name}
					</div>
				</div>
				}

				{ !isEVMPage && currentUser && currentUser.qudoManaged && (
					<div className="mb-2">
						<WalletMigrationCard />
					</div>
				)}

				{ !isEVMPage && // Only show the balance limit card - for TELOS Zero 
				<div className="mb-3">
					<BalanceLimitCard
						onClick={() => setMigrationModal(!migrationModal)}
					/>
				</div>
				}
				
				<div className="mb-3">
					<Transfer 
						chainId={chainId}
						detailColor={detailColor}
					/>
				</div>

				{ // MARK: SEND 
				}
				<div className="card roundcard shadow mb-3" style={{ borderColor:`${detailColor?detailColor:''}` }} >
					<div className="row px-4 pt-4 pb-2">
						<div className="col-1">
							<img src={send} className="mr-2" alt="send"/>
						</div>
						<div className="col-10">Send</div>
					</div>

					{ !currentUser.qudoManaged && !ual.activeUser && !isEVMPage ? (
						<>
							<div className="card-body px-4 pb-0 pt-3">
								Please sign in with one of the available wallets to send QUDO
							</div>
							<div className="card-body">
								<button
									type="button"
									className="std-button-active"
									onClick={() => {
										ual.logout();
										ual.restart();
										ual.showModal();
									}}
								>
									<div className="text-center">
										SIGN IN
									</div>
								</button>
								<button
									type="button"
									className="std-button mb-0"
									style={{backgroundColor: '#00cc69'}}
									onClick={() => setTutorialModal(!tutorialModal)}
								>
									<div className="text-center">
										HOW TO SIGN IN
									</div>
								</button>
							</div>
						</>
					) : (
						<>
						<div className="card-body px-4 pb-4 pt-0">
							<span>
								Send my QUDOs to other QUDO accounts, on {chain?chain.chainName:"Telos Zero"}
							</span>
							<div className="row mt-2">
								<div className="col-3 align-self-center">
									<div
										data-tip
										data-for='receiver'
										style={{fontWeight: 'bold', textAlign: 'right'}}
									>
										To
									</div>

									<ReactTooltip id='receiver' type='light'>
										<span>QUDO username OR TELOS account name</span>
									</ReactTooltip>
								</div>

								<div className="col-6 align-self-center">
									<input
										name="to"
										required
										className="w-100 send-to-input-box"
										title="username"
										minLength="4"
										value={searchTerm}
										onChange={(e) => {
											setSearchTerm(e.target.value);
											//setSearchTerm(String(e.target.value).toLowerCase());
										}}
									/>
								</div>

								<div className="col-3 rounded-circle">
									<img
										src={avatarLink}
										style={{height: "3rem", width: '3rem'}}
										alt="avatar for receiver"
									/>
								</div>
							</div>

							<div className="mt-3 text-center">
								<div style={{fontWeight: 'bold'}}>
									Search by:
								</div>

								<div>
									<div style={{display: 'inline-block', marginRight: '5px'}}>
										QUDO account name
									</div>
									<input
										type="radio"
										className="my-auto"
										checked={!searchByAccName}
										onChange={() => setSearchByAccName(!searchByAccName)}
									/>
								</div>

								<div>
									<div style={{display: 'inline-block', marginRight: '5px'}}>
										{isEVMPage ? `${chain.chainName} address` : "TELOS account name"}
									</div>
									<input
										type="radio"
										className="my-auto"
										checked={searchByAccName}
										onChange={() => setSearchByAccName(!searchByAccName)}
									/>
								</div>
							</div>

							{errorMessage && (
								<div className="row mt-3" style={{color: 'red', fontSize: '0.85rem'}}>
									<div className="col-12 text-center">
										{errorMessage}
									</div>
								</div>
							)}
						</div>

						<div className="card-body" style={{padding: "0 3rem"}}>
							<div className="row justify-content-center">
								<div className="col-12">Amount</div>
							</div>
							<div className="row justify-content-center" style={{borderBottom:`1px solid ${detailColor}`}}>
								<input
									type="number"
									name="quantity"
									className="col-12 col-xl-8 numbered-range-input"
									style={{border: "none"}}
									step={0.01}
									value={quantity}
									onChange={(e) => setQuantity(e.target.value)}
									onBlur={() => confirmAmount(quantity, maxBalance, 0, setQuantity)}
								/>
								<div className="col-12 col-xl-4 justify-content-right">
									Qudo
								</div>
							</div>
							{/* Disable the slide XDD*/}
							<div className="row justify-content-center mt-3">
								{ false && 
									<input
										required
										type="range"
										name="quantity"
										className="custom-range col-12"
										defaultValue="20"
										max={maxBalance}
										step={0.01}
										value={quantity}
										onChange={(e) => confirmAmount(e.target.value, maxBalance, 0, setQuantity)}
									/>
								}
							</div>
						</div>

						<div className="card-body">
							{sendLoading ? (
								<div className="text-center">
									<ClipLoader color="#282725"/>
								</div>
							) : (
								<button
									onClick={() => {
										/*
										if(currentUser && currentUser.qudoManaged) {
											setConfirmSendMessage('Are you sure?');
										} else {
											submit();
										}
										*/

										submit();
									}}
									type="button"
									className="std-button-active"
								>
									<div className="text-center">
										<div style={{ float: "left" }}>
											SEND QUDO
										</div>
										<img
											style={{ float: "right" }}
											src={arrow}
											alt="arrow"
										/>
									</div>
								</button>
							)}
						</div>
						</>
					)}
				</div>

				{showStakeComponent &&  // MARK: StakesComponent
				<div className="mb-3">
					<StakesComponent
						detailColor={detailColor}
					 />
				</div>
				}

				{currentUser && currentUser.type !== process.env.REACT_APP_GUILD_TYPE && showReferralComponent && ( // MARK: ReferralModal
				<div className="card roundcard shadow mb-3" style={{ borderColor:`${detailColor?detailColor:''}` }} >
					<div className="card-body">
						<div className="row mb-4">
							<div className="col-1">
								<img src={send} className="mr-2" alt="send"/>
							</div>
							<div className="col-9">Referrals</div>
						</div>
						<div className="row">
							<div className="col-12">
								<button
									type="button"
									className="std-button-active"
									onClick={() => setReferralModal(true)}
								>
									<div className="text-center">
										OPEN
									</div>
								</button>
							</div>
						</div>
					</div>
				</div>
				)}

				<div className="card roundcard shadow" style={{ borderColor:`${detailColor?detailColor:''}` }} >
					<div className="card-body">
						<div className="row mb-4">
							<div className="col-1">
								<img src={send} className="mr-2" alt="send"/>
							</div>
							<div className="col-9">Receive</div>
						</div>
						<div id="qr-code-area" className="row">
							<div className={
								document.getElementById('qr-code-area') && document.getElementById('qr-code-area').clientWidth < 450
								? 'col-12 receivetext' : 'col-8 receivetext'
							}>
								To receive QUDO, give this QR CODE to the person sending it.
								That's your TELOS account name!
							</div>
							<div className={
								document.getElementById('qr-code-area') && document.getElementById('qr-code-area').clientWidth < 450
								? 'col-12 text-center mt-3' : 'col-4'
							}>
								<div style={{display: 'inline-block'}}>
									<img
										src={`${process.env.REACT_APP_QUDO_SERVER}/profile/qr-code?width=150&rand=${Date.now()}&walletAddress=${isEVMPage ? walletAddress : currentUser.account_name}`}
										className="img-fluid"
										alt="QR code"
									/>
									<div 
										className="text-center mt-3 click-hover-animation"
										style={ isEVMPage && !isMobile 
											? {display:'flex',width:'150px', flexDirection:'row-reverse'}
											:{}
										}
									>
											
										{isEVMPage ? isMobile ? shortenAddress(walletAddress) : walletAddress : currentUser.account_name}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Form>

			<MessageModal
				show={successModal} 
				message={modalMessage}
				hide={() => setSuccessModal(!successModal)}
			/>

			<MessageModal
				show={failureModal} 
				message={modalMessage}
				hide={() => setFailureModal(!failureModal)}
			/>

			{/*}
			{confirmSendMessage && (
				<ConfirmModal
					show={confirmSendMessage}
					hide={() => setConfirmSendMessage('')}
					message={confirmSendMessage}
					confirm={() => submit()}
				/>
			)}
			{*/}

			{migrationModal && (
				<WalletMigration
					show={migrationModal}
					hide={() => setMigrationModal(!migrationModal)}
				/>
			)}

			{tutorialModal && (
				<WalletImportTutorial
					show={tutorialModal}
					hide={() => setTutorialModal(!tutorialModal)}
				/>
			)}

			{referralModal && (
				<ReferralModal
					show={referralModal}
					setShow={setReferralModal}
				/>
			)}
		</>
	);
}
