import React, { useEffect, useState } 	from "react";
import { Link, useHistory } 			from "react-router-dom";
import { useSelector, useDispatch } 	from "react-redux";
import ReactTooltip 					from "react-tooltip";

import QUDOServer 							from "helpers/QUDOServerConnection";
import { eligbleForWalletMultichainBeta, eligleForMainnetMigration } 	from "helpers/betasEliglebles";
import * as actions 	from "actions";

import WalletMultichainIcon 	from 'layouts/WalletMultichainIcon'

import Login 							from "components/components/modals/LoginUserModal";
import Verify 							from "components/components/modals/VerifyUserModal";
import Register 						from "components/components/modals/RegisterUserModal";
import ResetPassword 					from "components/components/modals/ResetPasswordModal";
import ForgotPassword 					from "components/components/modals/ForgotPasswordModal";
import ForgotUsername 					from "components/components/modals/ForgotUsernameModal";
import AcceptCookiesModal 				from "components/components/modals/AcceptCookiesModal";
import MainnetMigration 				from "components/common/mainnetMigration/mainnetMigration";
import AirdropRankingsModal 			from "components/components/modals/AirdropRankingsModal";
import { LoginMessageModal, MetaModal } from "components/components/modals/MetaModals";
import ResendEmail 						from "components/components/modals/ResendEmailModal";
import { TelegramGameClaimModal, TelegramGameMessageModal } from "components/components/modals/TelegramGameModal";
import { chains } from "helpers/EthereumContext";

const logo 				= require("images/definitive/qudo_logo.svg");
const homeIcon 			= require("images/definitive/icn-home.png");
const walletIcon 		= require("images/definitive/icn-wallet.png");
const gameActivityIcon 	= require("images/definitive/icn-cup.png");
const gamesListIcon 	= require("images/definitive/icn-gameact.png");
const marketplaceIcon 	= require("images/definitive/icn-marketplace.png");
const servicesIcon 		= require("images/definitive/icn-services-group.png");
const guildIcon 		= require("images/definitive/icn-guild-settings.png");
const taskOnIcon 		= require("images/definitive/icn-challenges-white.svg");
const taskOnWarnIcon 	= require("images/definitive/icn-challenges-warn-white.svg");
const vestingIcon 		= require("images/definitive/icn-product.png");
const fallBackAvatar 	= require("images/definitive/icon-avatar.png");

const vestingItem = {
	id: "Vesting",
	href: "https://games.qudo.io/vesting",
	src: vestingIcon,
	alt: "Vesting"
}
// MARK: MenuItems
const menuItems = (type) => {
	var items = [];

	if(type === process.env.REACT_APP_USER_TYPE) {
		items = [
			{
				id: "HomeIcon",
				href: process.env.REACT_APP_HOME,
				src: homeIcon,
				alt: "Home/Community"
			},
			{
				href: process.env.REACT_APP_USER_GAME_ACTIVITY,
				src: gameActivityIcon,
				alt: "Game activity"
			},
			{
				href: process.env.REACT_APP_USER_WALLET,
				src: walletIcon,
				alt: "Wallet"
			}
		];
	}

	if(type === process.env.REACT_APP_GAMEDEV_TYPE) {
		items = [
			{
				id: "HomeIcon",
				href: process.env.REACT_APP_HOME,
				src: homeIcon,
				alt: "Home/Community"
			},
			{
				href: process.env.REACT_APP_USER_GAME_ACTIVITY,
				src: gameActivityIcon,
				alt: "Game activity"
			},
			{
				href: process.env.REACT_APP_GAMEDEV_GAMES,
				src: gamesListIcon,
				alt: "Games"
			},
			{
				href: process.env.REACT_APP_GAMEDEV_MARKETPLACE,
				src: marketplaceIcon,
				alt: "Services marketplace"
			},
			{
				href: process.env.REACT_APP_USER_WALLET,
				src: walletIcon,
				alt: "Wallet"
			}
		];
	}

	if(type === process.env.REACT_APP_PROVIDER_TYPE) {
		items = [
			{
				id: "HomeIcon",
				href: process.env.REACT_APP_HOME,
				src: homeIcon,
				alt: "Home/Community"
			},
			{
				href: process.env.REACT_APP_USER_GAME_ACTIVITY,
				src: gameActivityIcon,
				alt: "Game activity"
			},
			{
				href: process.env.REACT_APP_PROVIDER_EARNINGS,
				src: servicesIcon,
				alt: "Services"
			},
			{
				href: process.env.REACT_APP_GAMEDEV_MARKETPLACE,
				src: marketplaceIcon,
				alt: "Services marketplace"
			},
			{
				href: process.env.REACT_APP_USER_WALLET,
				src: walletIcon,
				alt: "Wallet"
			}
		];
	}

	if(type === process.env.REACT_APP_GUILD_TYPE) {
		items = [
			{
				id: "HomeIcon",
				href: process.env.REACT_APP_HOME,
				src: homeIcon,
				alt: "Home/Community"
			},
			{
				href: process.env.REACT_APP_GUILD_SETTINGS,
				src: guildIcon,
				alt: "Guild"
			},
			{
				href: process.env.REACT_APP_USER_WALLET,
				src: walletIcon,
				alt: "Wallet"
			}
		];
	}

	//console.log(type, items);
	return items;
}
// MARK: urls
const urls = (info) => {
	return {
		[process.env.REACT_APP_USER_TYPE]: {
			avatar: `${process.env.REACT_APP_QUDO_SERVER}/api/avatars/getbyid?imageID=${info.imageID}&rand=${Date.now()}`
		},
		[process.env.REACT_APP_GAMEDEV_TYPE]: {
			avatar: `${process.env.REACT_APP_QUDO_SERVER}/api/images/getbygamedev?gamedev=${info.username}&rand=${Date.now()}`
		},
		[process.env.REACT_APP_PROVIDER_TYPE]: {
			avatar: `${process.env.REACT_APP_QUDO_SERVER}/api/images/getbyprovider?provider=${info.username}&rand=${Date.now()}`
		},
		[process.env.REACT_APP_GUILD_TYPE]: {
			avatar: `${process.env.REACT_APP_QUDO_SERVER}/api/images/getbyguild?guild=${info.username}&rand=${Date.now()}`
		}
	}
}

let eligleForMainnetMigration_i = 0 // Only show once the mainnet migration modal

// MARK: LAYOUT
export default function Layout({modal, setCommunityModal, children}) {
	const history = useHistory();
	const dispatch = useDispatch();

	const ual = useSelector(state => state.ual);
	const info = useSelector(state => state.info);
	const changePageWarn = useSelector(state => state.changePageWarning);

	const [url, setUrl] = useState(null);
	const [loggedIn, setLoggedIn] = useState(false);
	const [message, setMessage] = useState(null);
	const [activeModal, _setActiveModal] = useState(null);
	const [telegramId, setTelegramId] = useState(null);
	const [resendEmailData, setResendEmailData] = useState(null);
	const [challengesClick, setChallengesClick] = useState(false);
	// const [message, setMessage] = useState('Almost there! You just need to verify your email.');
	// const [activeModal, setActiveModal] = useState('success');

	useEffect(() => {
		if(info) {
			//console.log(info);
			setUrl(urls(info));
		} else {
			//checkAuthModals();
		}

		checkAuthModals();

		if(loggedIn) {
			setLoggedIn(false);

			if(
				info && (
					info.type === process.env.REACT_APP_USER_TYPE
					|| info.type === process.env.REACT_APP_GAMEDEV_TYPE
					|| info.type === process.env.REACT_APP_PROVIDER_TYPE
				)
			) {
				setMessage(null);
				setActiveModal("airdrop");
			}
		}
	}, [info]);

    useEffect(() => {
        return history.listen((location) => {
			//console.log(location);
			checkAuthModals();
        });
    }, [history]);
	
	useEffect(() => {
        if (modal) {
			setActiveModal(modal);
			setCommunityModal(null);
		}
    }, [modal]);

	// Wrapped
	function setActiveModal(modal) {
		if( changePageWarn ){
			const confirmLeave = window.confirm( String(changePageWarn) );
			if( !confirmLeave ) return;
		}
		_setActiveModal(modal);
	}

	function checkAuthModals() {
		const url = new URL(window.location.href);
		const params = new URLSearchParams(url.search);

		const login 	= params.get('login');
		const register 	= params.get('register');
		const verify 	= params.get('verify');
		const adsource 	= params.get('adsource');
		const resetpass = params.get('resetpass');
		// Change this for premigration
		// const mainnetMigration = process.env.REACT_APP_TELOS_ENVIRONMENT !== 'mainnet' && info && !info.mainnetAccountName;
		const mainnetMigration = eligleForMainnetMigration(info)

		if(mainnetMigration) {
			setTimeout(() => {
				// if(info && !info.migratedMainnet) {
				if(info && !info.migratedMainnet && eligleForMainnetMigration_i < 1) {
					eligleForMainnetMigration_i++; // so it only shows once
					setActiveModal('mainnetMigration');
				}
			}, 0.75 * 1000);
		} else if(verify) {
			setActiveModal('verify');
		} else if(login && !info) {
			setActiveModal('login');
		} else if(register && !info) {
			setActiveModal('register');
		} else if(adsource === 'telegram') {
			if (!info) {
				setActiveModal("telegramGame");
			} else {
				const telegramId = params.get('telegramid');
				setTelegramId(telegramId);
				setActiveModal("telegramGameSuccess");
			}
		} else if(resetpass && !info) {
			setActiveModal('resetpass');
		}
	}

	function logout(hideFeedback) {
		QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/auth/logout`, {}, {withCredentials: true})
		.then(() => {
			if(ual) {
				ual.logout();
			}

			// clear 'trasnfer' local storage
			for(const i in chains){ 
				localStorage.removeItem(`Transfer-${chains[i].chainId}`);
			}

			if(!hideFeedback) {
				showMessageModal('logged out', 'You\'re now logged out');
			}
		})
		.catch(error => {
			if(!hideFeedback) {
				showMessageModal('logged out', error.errorMessage);
			}
		})
		.finally(() => {
			//dispatch(actions.fetchUser(true));
		});
	}

	function showMessageModal(modal, msg) {
		setMessage(msg);
		setActiveModal(modal);
		setTimeout(() => dispatch(actions.fetchUser()), 1000);
	}

	function showResendEmailModal(msg, data) {
		setMessage(msg);
		setActiveModal('resendEmail');
		setResendEmailData(data)
	}

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="position-fixed" id="sidebar">
					<Link
						to={process.env.REACT_APP_HOME}
						className="d-flex flex-column align-items-center position-absolute mt-2"
						style={{ top: "1vh", margin: "auto" }}
					>
						<img id="logo" alt="logo" src={logo} />
					</Link>
					<div className="position-absolute inner-items-sidebar">
						<div
							className="d-flex flex-column align-items-center justify-content-between"
							style={{ height: "5rem" }}>
							{info &&
								menuItems(info.type).map((item) => {
									//console.log(info.type);
									return (
										<Link
											to={item.href}
											key={item.href}
											style={{
												opacity: (window.location.pathname === item.href ? "0.4" : "1"),
												margin: "0.3rem"
											}}
										>
											<img
												id={item.id ? item.id : ""}
												src={item.src}
												alt={item.alt}
												className="icon-32px"
											/>
										</Link>
									);
								})}
								{ eligbleForWalletMultichainBeta(info) &&
									<Link to={process.env.REACT_APP_USER_WALLETMULTICHAIN} key={'mwallet'} style={{ 
										margin: "0.3rem",
										opacity: (window.location.pathname === process.env.REACT_APP_USER_WALLETMULTICHAIN ? "0.4" : "1") 
									}} >
										<WalletMultichainIcon />
									</Link>
								}
								{ /* <a target='_blank'
            						rel='noopener noreferrer'
									href="https://challenges.qudo.io"
									style={{
										margin: '0.3rem 0.3rem 0.3rem 0.6rem'
									}}
									onClick={() => { setChallengesClick(true) }}
								>
									<img
										src={challengesClick ? taskOnIcon : taskOnWarnIcon}
										alt={"TaskOn Challenges icon"}
										className="icon-32px"
										style={{ height: challengesClick ? '32px' : '36px' }}
										data-tip
										data-for='Challenge'
									/>
								</a> */}
								{info.hasVesting && 
									<a href={vestingItem.href}
										style={{margin: "0.3rem"}} target="_blank" rel="noopener noreferrer">
										<img
											id={vestingItem.id ? vestingItem.id : ""}
											src={vestingItem.src}
											alt={vestingItem.alt}
											className="icon-32px"
										/>
									</a>
								}
						</div>
					</div>
					<div className="position-absolute meta-box-outer">
						<div className="d-flex flex-column align-items-center justify-content-between">
							<div onClick={() => setActiveModal(info ? "meta" : "login")} className="enable-click">
								<img
									alt="User avatar"
									className="rounded-circle"
									style={{height: "56px", width: "56px"}}
									src={url && url[info.type] ? url[info.type].avatar : fallBackAvatar}
									onError={(i) => i.target.src = fallBackAvatar}
								/>

								{!(url && url[info.type]) && (
									<div className="text-center">
										Log In
									</div>
								)}
							</div>
						</div>
					</div>
				</div> 
			</div>
			<div id="maincontainer" style={{ backgroundColor: "#fafafa" }}>
				<div
					className="row d-flex justify-content-around align-content-center"
					id="navbar"
				>
					<Link
						to={process.env.REACT_APP_HOME}
						className="d-flex ml-3 justify-content-center"
					>
						<img id="logo-navbar" alt="logo" src={logo} />
					</Link>
					<div className="m-auto">
						{info &&
							menuItems(info.type).map((item) => {
								return (
									<Link to={item.href} key={item.src} style={{marginRight: "10px"}}>
										<img
											id={item.id ? item.id : ""}
											src={item.src}
											alt={item.alt}
											className="icon-32px"
										/>
									</Link>
								);
							})}
							{ eligbleForWalletMultichainBeta(info) &&
								<Link to={process.env.REACT_APP_USER_WALLETMULTICHAIN} key={'mwallet'} style={{marginRight: "10px"}}>
									<WalletMultichainIcon />
								</Link>
							
							}
							{/* <a target='_blank'
            						rel='noopener noreferrer'
									href="https://challenges.qudo.io"
									style={{marginRight: "10px"}}
									onClick={() => { setChallengesClick(true) }}>
								<img
									src={challengesClick ? taskOnIcon : taskOnWarnIcon}
									alt={"TaskOn Challenges Icon"}
									className="icon-32px"
									style={{ height: challengesClick ? '32px' : '40px' }}
								/>
							</a> */}
							{info.hasVesting && 
								<a href={vestingItem.href}
									style={{marginRight: "10px"}} target="_blank" rel="noopener noreferrer">
									<img
										id={vestingItem.id ? vestingItem.id : ""}
										src={vestingItem.src}
										alt={vestingItem.alt}
										className="icon-32px"
									/>
								</a>
							}
					</div>
					<div onClick={() => setActiveModal(info ? "meta" : "login")} className="enable-click mr-4">
						<img
							alt="User avatar"
							className="rounded-circle"
							style={{height: "46px", width: "46px"}}
							src={url && url[info.type] ? url[info.type].avatar : fallBackAvatar}
							onError={(i) => i.target.src = fallBackAvatar}
						/>

						{!(url && url[info.type]) && (
							<div className="text-center" style={{fontSize: '0.9rem', marginTop: '-5px'}}>
								Log In
							</div>
						)}
					</div>
				</div>

				{children}

				<AcceptCookiesModal/>

				{activeModal === "verify" && (
					<Verify
						show={activeModal === "verify"}
						setShow={setActiveModal}
					/>
				)}
				{activeModal === "mainnetMigration" && (
					<MainnetMigration
						show={activeModal === "mainnetMigration"}
						setShow={() => {
							setActiveModal(null);
							if (process.env.REACT_APP_TELOS_ENVIRONMENT !== 'testnet') {
								logout(true);
							}
						}}
					/>
				)}
				<ResendEmail
					show={activeModal === "resendEmail"}
					setShow={setActiveModal}
					success={(msg) => {
						setLoggedIn(true);
						showMessageModal("success", msg);
					}}
					failure={(msg) => showMessageModal("failure", msg)}
					message={message}
					data={resendEmailData}
				/>
				<Login
					show={activeModal === "login"}
					setShow={setActiveModal}
					success={(msg) => {
						setLoggedIn(true);
						showMessageModal("success", msg);
					}}
					failure={(msg) => showMessageModal("failure", msg)}
					switchToRegister={() => setActiveModal("register")}
					switchToForgotPassword={() => setActiveModal("forgotpassword")}
					switchToForgotUsername={() => setActiveModal("forgotusername")}
					switchToResendEmail={(msg, data) => showResendEmailModal(msg, data)}
				/>
				<Register
					show={activeModal === "register"}
					setShow={setActiveModal}
					success={(msg) => showMessageModal("success", msg)}
					failure={(msg) => showMessageModal("failure", msg)}
				/>	
				<ResetPassword 
					show={activeModal === "resetpass"}
					setShow={setActiveModal}
					success={(msg) => showMessageModal("success", msg)}
					failure={(msg) => showMessageModal("failure", msg)}
				/>
				<ForgotPassword 
					show={activeModal === "forgotpassword"}
					setShow={setActiveModal}
					success={(msg) => showMessageModal("success", msg)}
					failure={(msg) => showMessageModal("failure", msg)}
				/>
				<ForgotUsername 
					show={activeModal === "forgotusername"}
					setShow={setActiveModal}
					success={(msg) => showMessageModal("success", msg)}
					failure={(msg) => showMessageModal("failure", msg)}
				/>
				<LoginMessageModal
					show={(activeModal === "success" || activeModal === "failure" || activeModal === "logged out") && message}
					setShow={setActiveModal}
					message={message}
				/>
				<TelegramGameMessageModal
					show={(activeModal === "telegramGame")}
					setShow={setActiveModal}
					register={() => setActiveModal("register")}
					login={() => setActiveModal("login")}
				/>
				<TelegramGameClaimModal
					show={(activeModal === "telegramGameSuccess")}
					setShow={setActiveModal}
					info={info}
					telegramId={telegramId}
				/>
				<MetaModal
					show={activeModal === "meta"}
					setShow={setActiveModal}
					logout={logout}
					airdrop={() => {
						setMessage(null);
						setActiveModal("airdrop");
					}}
					showAirdrop={
						info && (
							info.type === process.env.REACT_APP_USER_TYPE
							|| info.type === process.env.REACT_APP_GAMEDEV_TYPE
							|| info.type === process.env.REACT_APP_PROVIDER_TYPE
						)
					}
				/>
				{activeModal === "airdrop" && (
					<AirdropRankingsModal
						show={activeModal === "airdrop"}
						hide={() => {
							const url = new URL(window.location.href);
							const params = new URLSearchParams(url.search);
							const adsource = params.get('adsource');
							if (adsource === 'telegram') {
								const telegramId = params.get('telegramid');
								setTelegramId(telegramId);
								setActiveModal("telegramGameSuccess");
							} else {
								setActiveModal(null);
							}
						}}
					/>
				)}
			</div>
			<ReactTooltip id='Challenge' type='light'
				place="right" effect="solid" className="shadow-tooltip">
				<div>Click here to Claim</div>
				<div>points & Win Rewards</div>
			</ReactTooltip>
		</div>
	);
}
