import React, { useState, useEffect }   from 'react';
import { useDispatch, useSelector }     from 'react-redux'
import { chains, useEthereum }          from 'helpers/EthereumContext.js' 
import cssColors                        from 'helpers/cssColors'

import TransactionHistory               from 'components/common/transactionHistory'
import Send                             from 'components/walletMultichain/Send'
import WalletTotalBalance               from 'components/walletMultichain/TotalBalance'
import AddressUsernameDisplay           from 'components/walletMultichain/AddressUsernameDisplay' 
import WalletPieChart                   from 'components/walletMultichain/BalancesPieChart'


export default function WalletZeroPage(){
    const dispatch  = useDispatch();
    const eth       = useEthereum();
    
    const currentUser = useSelector(state => state.info);

    const balances              = useSelector(state => state.balance);
    const [balance, setBalance] = useState(0);  // telos zero QUDO balance
    const [savings, setSavings] = useState(0);  // pocket change
    const [stake, setStake]     = useState(0);  // telos zero QUDO staked

    function calculateBalance() {
        if(balances) {
            if(balances.balance) 
                setBalance(Number(balances.balance.split(' ')[0]));
            if(balances.savings) 
                setSavings(Number(balances.savings.split(' ')[0]));
            if(balances.stake) 
                setStake(Number(balances.stake.split(' ')[0]));
        }
    }

    useEffect(() => {
        if(eth.connected){
            eth.switchNetwork(chains.telosEVM.chainId)
            .catch((e)=>{console.error("Caught error switching network", e)})
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        console.log("exec calculateBalance 2")
        calculateBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [balances]);

    return(
        <div className="row col-12 wallet-main-container">
            <div className="col-sm-12 col-md-6 col-xl-4 column" style={{display: "flex", flexDirection: "column", gap: "16px"}}>
                <WalletTotalBalance 
                    Balance={balance}
                    Available={balance-stake}
                    Stake={stake}
                    detailColor={cssColors.QUDO_YELLOW}
                />
                <AddressUsernameDisplay 
                    name={currentUser.account_name}
                />
                <WalletPieChart title={"Qudo Tokens Allocation in Telos Zero"} chartData={[
                    {name:"Balance", color:cssColors.QUDO_YELLOW, value:balance-stake},
                    {name:"Stake", color:cssColors.QUDO_MEDIUM_GREY, value:stake},
                ]} /> 
            </div>

            <div className="col-sm-12 col-md-6 col-xl-4 column">
                <Send 
                    update={eth.updateBalances}
                    showStakeComponent={true}
                    showReferralComponent={true}
                    detailColor={cssColors.QUDO_YELLOW}
                />
            </div>

            <div className="col-sm-12 col-md-12 col-xl-4 column">
                <TransactionHistory />
            </div>
        </div>
    )
}