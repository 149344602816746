import React, { useEffect, useState }   from 'react';
import { useDispatch, useSelector }     from 'react-redux'
import * as actions                     from 'actions'

import { useEthereum, chains }      from 'helpers/EthereumContext.js' 
import QUDOServer                   from 'helpers/QUDOServerConnection';
import cssColors                    from 'helpers/cssColors'
import { compareObjects }           from 'helpers/utils';

import WalletTotalBalance           from 'components/walletMultichain/TotalBalance'
import AddressUsernameDisplay       from 'components/walletMultichain/AddressUsernameDisplay' 
import Send                         from 'components/walletMultichain/Send'
import { pages }                    from 'components/WalletMultichain';

const chain = chains.base;

export default function WalletBasePage(){
    const dispatch  = useDispatch();
    const eth       = useEthereum();
    const user      = useSelector(state => state.info);

    const loading = useState(true);
    const [lk, setLk] = useState(user.linkedAccounts || {})

    useEffect(()=>{
        const _lk = user.linkedAccounts || {}
        if( compareObjects(lk, _lk) )
            setLk(_lk)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    useEffect(()=>{
        const url   = `/profile/addevmaccount`
        const lk    = user.linkedAccounts || {}
        const walletAddress = eth.walletAddress
        const registedAddrs = lk[chain.backendReference]

        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER + url}`, {
            account: walletAddress,
            evm: chain.backendReference,
        }, { withCredentials: true })
        .then((d)=>{ if(!d.success) throw new Error(d.errorMessage)
            console.debug(`${chain.chainName} registering ${walletAddress}`, d)
            setLk(d.data)
            loading[1](false)
            //dispatch(actions.fetchUser()) // THIS MAKES SO THAT THE PAGE IS ALWAYS UPDATING
        })
        .catch((e)=>{
            console.debug(`${chain.chainName} error on registering`, e)
            localStorage.setItem( 'pageError', String(e.errorMessage) )
            dispatch(actions.setWalletPage(pages.LANDING.id))
        })

        if( String(walletAddress).toLocaleLowerCase() === String(registedAddrs).toLocaleLowerCase() ){
            loading[1](false)
        }

        console.log(`${chain.chainName} wallet new address `, walletAddress)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[eth.walletAddress])

    useEffect(()=>{
        eth.switchNetwork(chain.chainId)
        .catch((e)=>{console.warn("Error caught switching network", e)})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    return(
        <div className="row col-12 wallet-main-container">
            <div className="col-sm-12 col-md-6 col-xl-4 column" style={{display: "flex", flexDirection: "column", gap: "16px"}}>
                <WalletTotalBalance 
                    Balance={eth.balances[chain.chainId]}
                    detailColor={cssColors.QUDO_DARK_GREY}
                />
                <AddressUsernameDisplay 
                    name={lk[chain.backendReference]}
                />
            </div>

            <div className="col-sm-12 col-md-6 col-xl-4 column">
                <Send 
                    update={eth.updateBalances}
                    chainId={chain.chainId}
                    detailColor={cssColors.QUDO_DARK_GREY}
                />
            </div>

            <div className="col-sm-12 col-md-12 col-xl-4 column">
            </div>
        </div>
    )
}