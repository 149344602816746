import React, { useState, useEffect, useCallback }   from 'react';
import { useSelector }                  from 'react-redux';
import { OverlayTrigger, Tooltip }      from "react-bootstrap";

import { useEthereum, chains }  from 'helpers/EthereumContext.js' 
import { getFloat }             from 'helpers/utils'
import cssColors                from 'helpers/cssColors'
import { ClaimButton }          from 'components/common/transactionHistory'
import WalletTotalBalance       from 'components/walletMultichain/TotalBalance'
import AddressUsernameDisplay   from 'components/walletMultichain/AddressUsernameDisplay'
import WalletPieChart           from 'components/walletMultichain/BalancesPieChart'
import WalletConnection         from "components/walletMultichain/Connections"
import WalletConnectionButton   from "components/walletMultichain/ConnectionButton"
import { MessageModal } from 'components/components/modals/modals';

const pocketChangeTooltip = (
    <Tooltip placement="right" className="">
        <span className="text-light"><small>
            {`Funds in Pocket Change account should be spent exclusively within the Platform and Qudo Games`}
        </small></span>
    </Tooltip>
);


export default function WalletLandingPage(){
    const eth = useEthereum();

    const isMobile = ( window && window.innerWidth < 768);

    const currentUser = useSelector(state => state.info);

    const balances              = useSelector(state => state.balance);
    const [balance, setBalance] = useState(0);  // telos zero QUDO balance
    const [savings, setSavings] = useState(0);  // pocket change
    const [stake, setStake]     = useState(0);  // telos zero QUDO staked
    const [evmBalance, setEvmBalance]       = useState(0);
    const [baseBalance, setBaseBalance]     = useState(0);
    const [totalBalance, setTotalBalance]   = useState(0);

    const [modalMessage, setModalMessage]   = useState('')
    const [errorModal, setErrorModal]   = useState(false)
    

    const calculateBalance = useCallback(() => {
        if(balances) {
            if(balances.balance) 
                setBalance(Number(balances.balance.split(' ')[0]));
            if(balances.savings) 
                setSavings(Number(balances.savings.split(' ')[0]));
            if(balances.stake) 
                setStake(Number(balances.stake.split(' ')[0]));
        }
    }, [balances])

    useEffect(() => {
        console.log("Landing page mounted")

        const pageError = localStorage.getItem('pageError')
        if(pageError){
            localStorage.removeItem('pageError')
            setModalMessage(pageError)
            setErrorModal(true)
        }

        return ()=>{
            console.log("Landing page unmounted")
        }
    }, []);
    
    useEffect(() => {
        calculateBalance();
    }, [calculateBalance]);
    
    useEffect(()=>{
        setTotalBalance(balance + savings + evmBalance + baseBalance)
    }, [balance, savings, evmBalance, baseBalance]);
    
    useEffect(()=>{
        setEvmBalance(getFloat(eth.balances[chains.telosEVM.chainId], 0 ));
        setBaseBalance(getFloat(eth.balances[chains.base.chainId], 0));   
    }, [eth.balances])


    return(
    <div className="row col-12 wallet-main-container">
        {
            // MARK:  Left
        }
        <div className="col-sm-12 col-md-6 col-xl-4 column" style={{display: "flex", flexDirection: "column", gap: "16px"}}>
                <WalletTotalBalance 
                    Balance={totalBalance}
                />
                <AddressUsernameDisplay 
                    name={currentUser.account_name}
                />
                <div style={{margin:"16px 0px"}}>
                    <ClaimButton />
                </div>
                <WalletPieChart title={"Multi-chain Allocation"} chartData={[
                    {name:"Telos ZERO", color:cssColors.QUDO_YELLOW, value:balance},
                    {name:"Telos EVM", color:cssColors.QUDO_BLUE, value:evmBalance},
                    {name:"Base", color:cssColors.QUDO_DARK_GREY, value:baseBalance},
                    {name:"Pocket Change", color:cssColors.QUDO_MEDIUM_GREY, value:savings},
                ]} /> 

        </div>

        {
            // MARK:  Middle
        }
        <div className="col-sm-12 col-md-6 col-xl-4 column">
            <WalletConnection
                balance={balance}
                baseBalance={baseBalance}
                evmBalance={evmBalance} 
            />

            <div style={{padding:"16px 32px"}}>
                <WalletConnectionButton title={"Pocket Change"} color={cssColors.QUDO_MEDIUM_GREY}
                    connected={true}
                    amount={savings?savings:0}
                    conBtn_Text={
                        <OverlayTrigger 
                            placement={ `${ isMobile ? "top" : "right" }` }
                            overlay={ pocketChangeTooltip }>
                            <span style={{ height:'100%', margin: '-1px -5px', // these to compensate for the button
                                fontSize:'24px', fontWeight:'bolder', color:cssColors.QUDO_YELLOW,
                                display:'flex', justifyContent:'center', alignItems:'center',
                            }}>
                                ?
                            </span>
                        </OverlayTrigger>
                    }
                />
            </div>

            <div style={{padding:'36px 0px', fontSize:'12px'}} className='text-center'>
                <i>When transferring or sending QUDO to other chains, some delays may occur.</i>
            </div>
        </div>

        {
            // MARK:  Right
        }
        <div className="col-sm-12 col-md-12 col-xl-4 column">

        </div>

        <MessageModal
            show={errorModal} 
            message={modalMessage}
            hide={() => setErrorModal(false)}
        />
    </div>
    )
}