export function isNumber(value) {
    if (typeof value === 'number' && !isNaN(value)) {
        return true;
    }
    if (typeof value === 'string' && !isNaN(value) && value.trim() !== '') {
        return true;
    }
    return null;
}

export function getNumber(value, defaultValue=null) {
    if (typeof value === 'number' && !isNaN(value)) {
        return value;
    }
    if (typeof value === 'string' && !isNaN(value) && value.trim() !== '') {
        return Number(value);
    }
    return defaultValue;
}

export function getFloat(value, defaultValue=null) {
    if (typeof value === 'number' && !isNaN(value)) {
        return value;
    }
    if (typeof value === 'string' && !isNaN(parseFloat(value)) && value.trim() !== '') {
        return parseFloat(value);
    }
    return defaultValue;
}

// locks 'value' between 'max-min', and calls 'setQuantity' at the end.
export function confirmAmount(value, maxBalance, minBalance = 0, setQuantity = () => {}) {
    const min = getFloat(minBalance, 0);
    const max = getFloat(maxBalance, 0);

    //Replace commas with dots
    if(!value || isNaN(value)) {
        value = parseFloat(String(value).replace(',', '.'));
    }

    //Replace dots with commas
    if(!value || isNaN(value)) {
        value = parseFloat(String(value).replace('.', ','));
    }

    value = parseFloat(value);

    if(!value || isNaN(value)) {
        value = min;
    } else {
        value = value > max ? max : value;
        value = value < min ? min : value;
    }

    value = Number(parseFloat(value).toFixed(4));
    setQuantity(value);
    return value;
}

/**returns true if 'objA' === 'objB' 
 *  */ 
export function compareObjects(objA, objB){
    if (objA === objB) return true;

    if (typeof objA !== 'object' || objA === null || typeof objB !== 'object' || objB === null) {
        return false;
    }

    const keysA = Object.keys(objA);
    const keysB = Object.keys(objB);

    if (keysA.length !== keysB.length) {
        return false;
    }

    for (const key of keysA) {
        if (!keysB.includes(key) || !compareObjects(objA[key], objB[key])) {
            return false;
        }
    }

    return true;
}

/**
 * Converts an RGBA color string to its hexadecimal representation.
 *
 * @param {string} rgba - The RGBA color string (e.g., "rgba(255, 255, 255, 0.5)").
 * @returns {string} The hexadecimal representation of the color, including alpha (e.g., "#ffffff80").
 *
 * @note
 * commas <,> are optional
 * 
 * @example
 * rgbaToHex("rgba(255 255 255)");     // Returns "#ffffff"
 * rgbaToHex("rgba(0, 128, 255, 1)");  // Returns "#0080ffff"
 */
export function rgbaToHex(rgba) {
    const parts = rgba.match(/[\d.]+/g); 
    const r = parseInt(parts[0]); 
    const g = parseInt(parts[1]); 
    const b = parseInt(parts[2]); 
    const a = parts[3] !== undefined ? Math.round(parseFloat(parts[3]) * 255) : 255; // Alpha (default to 255)

    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}${a.toString(16).padStart(2, '0')}`;
}
