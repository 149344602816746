import { ButtonInput } from "components/components/forms/buttons";
import { AccNameInput } from "components/components/forms/text";
import { MessageModal } from "components/components/modals/modals";
import React, { useState, useEffect } from "react";

export function MainnetAccountName(props) {
	const [message, setMessage] = useState('');

	const [updateAccountname, setUpdateAccountname] = useState(false);
	const [randomizeAccountName, setRandomizeAccountName] = useState(false);

	//When checkboxes change re-check accountname validity
	useEffect(() => {
		setUpdateAccountname(!updateAccountname);
	}, [props.newAccount]);

	//Reset button state (button not doing this for some reason)
	useEffect(() => {
		if(randomizeAccountName) {
			setRandomizeAccountName(false);
		}
	}, [randomizeAccountName]);

	//Check when the parent component forces the account name randomization
	useEffect(() => {
		if(props.randomizeAccountName) {
			setRandomizeAccountName(true);
			props.setRandomizeAccountName(false);
		}
	}, [props.randomizeAccountName]);

	//Show a warning when user says he wants to use his own TELOS account
	useEffect(() => {
		if(!props.newAccount) {
			setMessage(
				<>
				{`ATTENTION: You must have an already existing TELOS account and have permissions to use it!`}
				<br/>
				<button
                    type="button"
                    className="std-button"
                    style={{ 
						backgroundColor: '#656565',
						height: '48px',
						maxWidth: '192px',
						margin: '0px',
						marginTop: '16px',
						padding: '0px',
					}}
                    onClick={() => setMessage('')}
                >	<div className="text-center" style={{ color: '#ededed' }}>
                        CLOSE
                    </div>
                </button>
				</>
			);
		}
	}, [props.newAccount]);

	return(
		<div className="my-3">
			<div className="row">
				<div
					className="col-12"
					style={{marginBottom: '1rem'}}
				>
					<h5>
						Do you have a TELOS account?
					</h5>
				</div>

				<div
					className="col-12"
					style={{marginBottom: '1rem'}}
				>
					<div
						className="row"
						onClick={() => props.setNewAccount(true)}
					>
						<input
							type="checkbox"
							className="col-2 my-auto"
							checked={props.newAccount}
							readOnly
							// onChange={() => props.setNewAccount(!props.newAccount)}
						/>
						<div className="col-9">
							No, create a new TELOS account
						</div>
					</div>
				</div>

				<div
					className="col-12"
					style={{marginBottom: '1rem'}}
				>
					<div
						className="row"
						onClick={() => props.setNewAccount(false)}
					>
						<input
							type="checkbox"
							className="col-2 my-auto"
							checked={!props.newAccount}
							readOnly
							// onChange={() => props.setNewAccount(!props.newAccount)}
						/>
						<div className="col-9">
							Yes, I will add my own TELOS account
						</div>
					</div>
				</div>
			</div>

			<div className="row">
				<div className={`${props.newAccount ? "col-8" : "col-12"}`}>
					<AccNameInput
						set={props.set}
						setValid={props.setValid}
						username={props.username}
						newAccount={props.newAccount}
						defaultAccountName={props.newAccountName}
						checkAccountnameValidity={updateAccountname}
						randomizeAccountName={randomizeAccountName}
						mainnet={true}
					/>
				</div>

				{props.newAccount && (
					<div className="col-4 p-0 text-center">
						<ButtonInput
							trigger
							initial={false}
							set={setRandomizeAccountName}
							className="form-submit-btn text-center mt-0 mx-0"
							style={{padding: '0px'}}
							label={
								<div>
									{/*}
									<img
										alt="Randomize TELOS account name"
										src={randomizeIcon}
										style={{maxWidth: '35px', maxHeight: '35px'}}
									/>
									{*/}
									<div>
										Randomize
									</div>
								</div>
							}
						/>
					</div>
				)}
			</div>

			<MessageModal
				show={!!message}
				message={message}
				hide={() => setMessage('')}
			/>
		</div>
	);
}