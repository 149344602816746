
const specialEmails = [
    'powaranja.azul@gmail.com',
    '1jenaike7@gmail.com',
    'joao.abrantes.80@gmail.com',
    'diogo1994_23@hotmail.com',
]

export function eligbleForWalletMultichainBeta(userInfo){
    if( !userInfo ) return false
    if( typeof userInfo !== "object" ) return false

    // Good Conditions
    const email = String(userInfo.email);
    if( email.endsWith('@blockbastards.io') )
        return true
    if( specialEmails.includes(email) )
        return true

    return false
}

export function eligleForMainnetMigration(userInfo){
    if( !userInfo )  return false
    if( typeof userInfo !== "object" ) return false

    if( userInfo.migratedMainnet )
        return false
    if( process.env.REACT_APP_TELOS_ENVIRONMENT !== 'mainnet' )
        if( userInfo.mainnetAccountName )
            return false
    if( process.env.REACT_APP_TELOS_ENVIRONMENT === 'mainnet' )
        return true
    
    // Good Conditions
    const email = String(userInfo.email);
    if( email.endsWith('@blockbastards.io') )
        return true
    if( specialEmails.includes(email) )
        return true

    return false
}

export function eligleForMetakeep(userInfo){
    if( !userInfo ) return false
    if( typeof userInfo !== "object" && typeof userInfo !== "string" ) return false
    
    // Good Conditions
    const email = typeof userInfo !== "object" ? String(userInfo.email) : String(userInfo);
    if( email.endsWith('@blockbastards.io') )
        return true
    if( specialEmails.includes(email) )
        return true

    return false
}