import { combineReducers } from "redux";
import infoReducer from "./infoReducer";
import ualReducer from "./ualReducer";
import balanceReducer from "./balanceReducer";
import pendingRewardReducer from "./pendingRewardReducer";
import configReducer from "./configReducer";
import gamesReducer from "./gamesReducer"
import blockReducer from "./blockReducer"
import walletPageReducer from "./walletPageReducer"
import changePageWarningReducer from "./changePageWarningReducer";

import { connectRouter } from "connected-react-router";

const createRootReducer = history =>
	combineReducers({
		router: connectRouter(history),
		info: infoReducer,
		ual: ualReducer,
		balance: balanceReducer,
		pendingReward: pendingRewardReducer,
		config: configReducer,
		games: gamesReducer,
		block: blockReducer,
		chosenWalletPage: walletPageReducer,
		changePageWarning: changePageWarningReducer,
	});
export default createRootReducer;
