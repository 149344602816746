import { SET_CHANGE_PAGE_WARNING } from 'actions/types';

export default function(state = "", action) {
    switch (action.type) {
        case SET_CHANGE_PAGE_WARNING:
            return action.payload || false;
        default:
            return state;
    }
}
