import React, { useState, useEffect, useCallback }      from "react";
import { useDispatch, useSelector }                     from 'react-redux'
import { ClipLoader }                                   from "react-spinners";
import { arrayToHex }                                   from "eosjs/dist/eosjs-serialize";
import * as _actions                                    from "actions";

import { useEthereum, chains, findByChainId, rules }    from 'helpers/EthereumContext.js' 
import QUDOServer                                       from 'helpers/QUDOServerConnection';
import * as MK                                          from 'helpers/metakeep.js'
import { confirmAmount, getFloat }                      from "helpers/utils";

import { MessageModal }                                 from "components/components/modals/modals";
import WalletImportTutorial                             from "components/common/walletMigration/walletImportTutorial";

import { Modal }    from "react-bootstrap";
import { eligleForMetakeep } from "helpers/betasEliglebles";

const EXECUTEDMSG = 'done'
const CURREXECMSG = 'in progress'
const WAITEXECMSG = ''

const send  = require("images/definitive/icn-send-qudos.png");
const arrow = require("images/definitive/icn-arrow-forward.png");

const minBridgeBalance = 1

export const FUNC = Object.freeze({
    PRINT_NETWORK:      'printNetwork',  ///  for debugging
    SWITCH_NETWORK:     'switchNetwork', ///  eth.switchNetwork           arg[0] = chainId
    PERMISSION:         'permission',    ///  eth.permissionToBridgeBack  arg[0] = amount
    BRIDGE:             'bridge',        ///  eth.ethersBridge            arg[0] = receiver  arg[1] = amount    arg[2] = chainId
    BRIDGE_BACK:        'bridgeBack',    ///  eth.ethersBridgeBack        arg[0] = amount    arg[1] = receiver  
    WAIT_BALANCE:       'waitBalance',   ///  wait enough balance         arg[0] = amount    arg[1] = chainId
});

function createAction({
    completed   = false,        // if the action is completed, set by the function
    message     = '',           // message to display in the modal - optional, empty is hidden
    func        = 'unknown',    // function to call, from FUNC.
    args        = [],           // arguments to pass to the function
    error       = null,         // save the new Error.message here
    options     = {}            // specific options for the function, you can also write here for 'permanent' information
    /* options include like:
        - source_chainId    (by: BRIDGE)
        - timeout           (by: WAIT_BALANCE)
        - stopped           (by: WAIT_BALANCE)
        - success_message   (by: all)
            For - setModalMessage(options['success_message']); setSuccessModal(true); - when it finishes
    */
} = {}) {
    return { completed, message, func, args, options };
}


export default function Transfer({
	chainId			        = 0,     // Chain ID for the opened chain, where we execute actions / 0 = Telos Zero
    detailColor,	                 // Background shadow color
}) {
    const dispatch  = useDispatch()
    const eth       = useEthereum()
    const isEVM     = chainId !== 0
    const chain     = findByChainId(chainId)

    const ual           = useSelector(state => state.ual)
    const balance       = useSelector(state => state.balance)
    const currentUser   = useSelector(state => state.info)
    
    const [loading, setLoading]             = useState(false)
    const [submitting, setSubmitting]       = useState(false)
    const [modalMessage, setModalMessage]   = useState('')
    const [successModal, setSuccessModal]   = useState(false)
    const [failureModal, setFailureModal]   = useState(false)
    const [errorMessage, setErrorMessage]   = useState('')
    const [tutorialModal, setTutorialModal] = useState(false)
    
    const [chainSelected, setChainSelected] = useState(-1)
    const [quantity, setQuantity]           = useState(0)
    const [maxBalance, setMaxBalance]       = useState(0)
    
    const [actionsList, setActionsList]     = useState([])
    const [actionsPaused, setActionsPaused] = useState(true)
    const [actionsModal, setActionsModal]   = useState(false)
    const [unfinishedActions, setUnfinishedActions] = useState(false)

    const localStorageKey                   = `Transfer-${chainId}`     // this is also used in logout of layout

    let currentExecution = actionsList.findIndex( action => action.completed === false );
    if( currentExecution < 0  ) currentExecution = actionsList.length

    // MARK: Hooks

    useEffect(() => {
        const _actions = JSON.parse( localStorage.getItem( localStorageKey, JSON.stringify(actionsList) ) )
        console.debug("Read actions from localstorage", _actions)
        if(     _actions 
            &&  Array.isArray(_actions)
            &&  _actions.length>0
            &&  _actions.findIndex(a=>a.completed===false) 
          ){
            setUnfinishedActions(true)
            setActionsModal(true)
            setActions(_actions)
        }
        updateBalance();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        updateBalance();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [balance.balance, eth.balances]);

    useEffect(() => {
        console.log("actionsModal", actionsModal)
    },[actionsModal])

    // actionsList, pausedActions
    useEffect(() => {
        if(global.isDev) console.debug('ActionsList ', JSON.parse(JSON.stringify(actionsList)));
        if(actionsPaused){
            console.debug("ActionsList paused")
            return;
        }

        if(actionsList.length === 0){
            console.debug("ActionsList empty")
            return;
        }

        const i = actionsList.findIndex( action => action.completed === false );

        if( i === -1 ) {
            console.debug('ActionsList - All actions completed')
            setActions()
            return
        };

        const lk = currentUser.linkedAccounts || {};

        const action = actionsList[i];
        switch (action.func) {

            case FUNC.PRINT_NETWORK:
                if(global.isDev) console.log(`[${i}] Transfer <${FUNC.BRIDGE}> - Current Network`, eth.network);
                finishAction(actionsList, i);
                return

            case FUNC.SWITCH_NETWORK:
                const targetChainId = action.args[0]
                eth.switchNetwork( targetChainId )
                .then( ()=>{
                    finishAction(actionsList, i);
                    if(global.isDev) console.log("Transfer - Switched to chainId", targetChainId);
                })
                .catch( (e)=>{
                    console.error(`[${i}] Transfer <${FUNC.SWITCH_NETWORK}> - error`, e)
                    errorAction(actionsList, i, e)
                })
                return

            case FUNC.BRIDGE:
                const source_chainId = action.options['source_chainId']
                if( source_chainId && source_chainId !== eth.network.chainId) { // Not on the correct chain
                    eth.switchNetwork( source_chainId )
                    .then( ()=>{
                        if(global.isDev) console.log(`[${i}] Transfer <${FUNC.BRIDGE}> - Switched to <${source_chainId}>`);
                        repeatAction(actionsList);
                    })
                    .catch( (e)=>{
                        console.error(`[${i}] Transfer <${FUNC.BRIDGE}> - Switch error`, e)
                        errorAction(actionsList, i, e)
                    })
                    return
                }
                const bridge_to = action.args[0]
                const bridge_amount = action.args[1]
                const bridge_chain = action.args[2]
                eth.ethersBridge( bridge_to, bridge_amount, bridge_chain )
                .then( ( tx )=>{
                    if(global.isDev) console.log(`[${i}] Transfer <${FUNC.BRIDGE}> - Bridged`, tx);
                    finishAction(actionsList, i);
                })
                .catch( (e)=>{
                    console.error(`[${i}] Transfer <${FUNC.BRIDGE}> - error`, e)
                    errorAction(actionsList, i, e)
                })
                return

            case FUNC.PERMISSION:
                if( eth.network.chainId !== chains.telosEVM.chainId ){ // Not on tEVM
                    eth.switchNetwork( chains.telosEVM.chainId )
                    .then( ()=>{
                        if(global.isDev) console.log(`[${i}] Transfer <${FUNC.PERMISSION}> - Switched to EVM`);
                        repeatAction(actionsList);
                    })
                    .catch( (e)=>{
                        console.error(`[${i}] Transfer <${FUNC.PERMISSION}> - Switch error`, e)
                        errorAction(actionsList, i, e)
                    })
                    return
                } // in EVM
                const amount = action.args[0]
                eth.permissionToBridgeBack( String(amount) )
                .then( ( tx )=>{
                    if(global.isDev) console.log(`[${i}] Transfer <${FUNC.PERMISSION}> - Permission granted`, tx);
                    finishAction(actionsList, i);
                })
                .catch( (e)=>{
                    console.error(`[${i}] Transfer <${FUNC.PERMISSION}> - error`, e)
                    errorAction(actionsList, i, e)
                })
                return

            case FUNC.BRIDGE_BACK:
                if( eth.network.chainId !== chains.telosEVM.chainId ){ // Not on tEVM
                    eth.switchNetwork( chains.telosEVM.chainId )
                    .then( ()=>{
                        if(global.isDev) console.log(`[${i}] Transfer <${FUNC.BRIDGE_BACK}> - Switched to EVM`)
                            repeatAction(actionsList)
                    })
                    .catch( (e)=>{
                        console.error(`[${i}] Transfer <${FUNC.BRIDGE_BACK}> - Switch error`, e)
                        errorAction(actionsList, i, e)
                    })
                    return
                } // in EVM
                const amountToBridgeBack = action.args[0]
                const receiver = action.args[1]
                eth.ethersBridgeBack( String(amountToBridgeBack), receiver )
                .then( ( tx )=>{
                    if(global.isDev) console.log(`[${i}] Transfer <${FUNC.BRIDGE_BACK}> - Bridged back`, tx)
                    finishAction(actionsList, i)
                })
                .catch( (e)=>{
                    console.error(`[${i}] Transfer <${FUNC.BRIDGE_BACK}> - error`, e)
                    errorAction(actionsList, i, e)
                })
                return
            
            case FUNC.WAIT_BALANCE:
                const amountToWait  = action.args[0]
                const chainIdToWait = action.args[1]
                if( chainIdToWait !== eth.network.chainId ){ // Not on the correct chain
                    eth.switchNetwork( chains.telosEVM.chainId )
                    .then( ()=>{
                        if(global.isDev) console.log(`[${i}] Transfer <${FUNC.WAIT_BALANCE}> - Switched to EVM`)
                        repeatAction(actionsList)
                    })
                    .catch( (e)=>{
                        console.error(`[${i}] Transfer <${FUNC.WAIT_BALANCE}> - Switch error`, e)
                        errorAction(actionsList, i, e)
                    })
                    return
                } // in correct chain
                let balance = 0;

                const walletAddressToWait = lk[findByChainId(eth.network.chainId).backendReference]
                let intervalId = setInterval(async () => {
                    try {
                        balance = await eth.getBalanceOf(walletAddressToWait, chainIdToWait);
                        if (balance >= getFloat(amountToWait)) {
                            clearInterval(intervalId)
                            finishAction(actionsList, i)
                            return;
                        }
                    } catch (error) {
                        console.error("Error while waiting for balance update:", error);
                        clearInterval(intervalId);
                        repeatAction(actionsList)
                        return;
                    }
                }, 1000);

                const WAIT_BALANCE_timeout = action.options['timeout'] // || 60000; // default to 60 seconds
                if( WAIT_BALANCE_timeout && !isNaN(WAIT_BALANCE_timeout) ){
                    setTimeout(() => {
                        action.options['stopped'] = true;
                        console.warn("Waiting for balance timed out");
                        clearInterval(intervalId);
                    }, Number(WAIT_BALANCE_timeout));
                }

                break;

            default:
                console.error("Transfer - Unknown action", actionsList[i]);
                finishAction(actionsList, i);
                break
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionsList, actionsPaused])

    useEffect(() => {
        if( submitting || ( actionsList.length !== 0 ) )
            setLoading(true)
        else
            setLoading(false)
    }, [submitting, actionsList])


    // MARK: ACTIONs
    function setActions(actions, unpause){
        if( actions === undefined ) actions = []
        localStoreAction(actions)

        if(actionsList.length === 0 && actions.length === 0) // do not set again...
            return

        if(unpause)
            setActionsPaused(false)
        
        setActionsList([...actions])
    }

    function finishAction(actions, i){
        if( actions === undefined ) actions = []
        if( i && i >= actions.length ) throw new Error("Finish Action - index out of range")
        else if( i >= 0 ){ // no i = skip
            actions[i].completed = true;
            actions[i].error = null
            if( actions[i].options['success_message'] ){
                setActionsModal( false ) // close if open
                setModalMessage( actions[i].options['success_message'] )
                setSuccessModal( true )
            }
        }

        setActions(actions)
    }

    function errorAction(actions, i, error){
        if( actions === undefined ) actions = []
        if( i && i >= actions.length ) throw new Error("Error Action - index out of range")
        else if( i >= 0 ){ // no i = skip
            setActionsPaused( true )
            actions[i].completed = false;
            actions[i].error = error.message
            if(!actionsModal){ // actionsModal closed ? show the error this way
                setModalMessage( error.message )
                setFailureModal( true )
            }
            // setActionsModal( false ) // close the actionsModal
        }

        setActions(actions)
    }

    function repeatAction(actions){
        setActions(actions)
    }

    function localStoreAction(actions){
        const currExecPosition = actions.findIndex( action => action.completed === false )
        if( currExecPosition === -1 || actions.length <= 0 ) { // actions finished OR empty ?
            localStorage.removeItem( localStorageKey )
            return
        }

        actions = JSON.parse(JSON.stringify(actions))
        for(const a of actions){ a.error=null } // don't save errors

        localStorage.setItem( localStorageKey, JSON.stringify(actions) )
    }



    const updateBalance = useCallback( ()=>{
        if(!isEVM){
            setMaxBalance(balance.balance);
            return;
        }
        if(isEVM){
            setMaxBalance(eth.balances[chainId]);
            return;
        }
    }, [balance.balance, chainId, eth.balances, isEVM] )

    // MARK: submit EVM
    async function submitEVM(){
        if(global.isDev) console.log(`Send submit: EVM & Chain ID <${chainId}>`);

        const selectedChain = findByChainId(chainSelected || chains.telosEVM.chainId); // default to tEVM
        const actions = []
        const lk = currentUser.linkedAccounts || {};
        const to = lk[selectedChain.backendReference]

        if(!to){
            setErrorMessage(`Can not transfer to ${selectedChain.chainName}, as you do not have any address registered for that chain.`)
            return
        }

        if(chainId !== eth.network.chainId){
            console.error(`Send submit: Chain ID mismatch <${chainId}> != <${eth.network.chainId}> `);
            actions.push(createAction({ func: FUNC.SWITCH_NETWORK, args: [chain.chainId], }))
        }

        if( chainSelected !== 0 ) { // meaning - between any EVM
            if(global.isDev) console.log(`Send submit: between EVMs <${chainId}> to <${chainSelected}>`);
            
            
            actions.push(createAction({
                func: FUNC.BRIDGE,
                args:[to, quantity, chainSelected],
                options:{'success_message': `Success! The balance on ${selectedChain.chainName} will update after the bridging is completed`},
            }))
            setActions(actions, true)
            return;
        } 

        // meaning - between any EVM to Telos Zero   -   To bridge back, into original chain
        // the selectedChain defaulted to tEVM

        if( quantity < rules.minimum_bridge_amount ){
            setModalMessage( `Minimum amount to bridge is ${rules.minimum_bridge_amount} QUDO` );
            setFailureModal(true);
            return;
        }

        if(global.isDev) console.log(`[Transfer][submitEVM]: EVMs <${chainId}> to Telos Zero `);

        // From any chain to ZERO, we pass otherChain -> tEVM (to then tEVM -> ZERO)
        if( chainId !== chains.telosEVM.chainId ){ 
            if(global.isDev) console.log(`[Transfer][submitEVM]: EVMs <${chainId}> to Telos Zero bridge `);
            
            // First bridge to Telos EVM
            actions.push(createAction({ 
                func: FUNC.BRIDGE,
                args:[to, quantity, chains.telosEVM.chainId],
                options:{'source_chainId': chainId},
                message: "Bridging to Telos EVM",
            }))
            
            actions.push(createAction({ func: FUNC.SWITCH_NETWORK, args: [chains.telosEVM.chainId], }))

            actions.push(createAction({
                func: FUNC.WAIT_BALANCE,
                args: [quantity, chains.telosEVM.chainId],
                message: `Waiting for ${quantity.toFixed(4)} QUDO on ${chains.telosEVM.chainName} `,
            }))
        }

        // Ask for permission to spend the user's tokens on the EVM Zero
        actions.push(createAction({
            func: FUNC.PERMISSION,
            args: [quantity],
            message: `Set allowance of ${quantity.toFixed(4)} QUDO to be bridge`,
        }))

        // Bridge ack the tokens to Telos Zero
        actions.push(createAction({
            func: FUNC.BRIDGE_BACK,
            args: [String(quantity), currentUser.account_name],
            message: `Bridge ${quantity.toFixed(4)} QUDO to Telos Zero`,
            options:{'success_message': `Success. You will receive your tokens on Telos Zero account in 30 to 60 seconds`},
        }))

        // Back to the original chain
        actions.push(createAction({ func: FUNC.SWITCH_NETWORK, args: [chainId], }))
        
        setActions(actions, true)
    } // bridge back to Telos Zero - completed


    // MARK: TLOS submit
    /// From TLOS -> EVMs
    async function submitTLOS(){
        // receiver should be the bridge !
        const receiver = process.env.REACT_APP_CONTRACT_ACCOUNT_ZERO_BRIDGE_TESTNET;
        const receiverInfo = null;
        //const chain         = findByChainId(chainId);
        const selectedChain = findByChainId(chainSelected);
        const lk = currentUser.linkedAccounts || {};
        const to = lk[selectedChain.backendReference]   // to address of selected chain
        const telosEVMTo = lk[chains.telosEVM.backendReference] // telos to address

        if(!telosEVMTo || telosEVMTo === ''){
            setErrorMessage(`Can not transfer to ${selectedChain.chainName}, as you do not have any address registered for ${chains.telosEVM.chainName} chain.`)
            return
        }

        if(!to || to === ''){
            setErrorMessage(`Can not transfer to ${selectedChain.chainName}, as you do not have any address registered for that chain.`)
            return
        }

        const actions = []
        let failed = false

        if( minBridgeBalance > await eth.getBridgerNativeBalance() ){
            setModalMessage("Not enough funds on Qudo Bridge, try again later")
            setFailureModal(true)
            return;
        }

        if(currentUser.qudoManaged){
            try{
                // Already have the Metakeep released!
                if(process.env.REACT_APP_METAKEEP_RELEASED === 'true' || eligleForMetakeep(currentUser) ){
                    const account = process.env.REACT_APP_TOKENCONTRACT;
                    const actions = [{
                        "account": account,
                        "name": "transfer",
                        "authorization": [{
                            "actor": currentUser.account_name,
                            "permission": "active"
                        }],
                        "data": {
                            from: currentUser.account_name,
                            to: receiver,
                            quantity: `${Number(quantity).toFixed(4)} ${process.env.REACT_APP_TOKENNAME}`,
                            memo: `${telosEVMTo}`,
                        }
                    }];

                    const MK_signResponse = await MK.signTransaction(currentUser.email, actions, {broadcast: false})
                    //const backendSent = 
                    await QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/eos/send`, {
                        from: currentUser.account_name,
                        to: receiver,
                        to_username: receiverInfo ? receiverInfo.username : null,
                        quantity: quantity,
                        memo: `${telosEVMTo}`,
                        signature: MK_signResponse.unpackedTransaction,
                    }, { withCredentials: true } )
                } 
                
                else
                // Metakeep not yet released
                { // Previous version, without metakeep // TODO: DELETE WHEN METAKEEP FULLY RELEASED
                    //const backendSignedTrans = 
                    await QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/eos/send`, {
                        from: currentUser.account_name,
                        to: receiver,
                        to_username: receiverInfo ? receiverInfo.username : null,
                        quantity: quantity,
                        memo: `${telosEVMTo}`,
                        signature: null,
                    }, {  withCredentials: true } )
                }
            } catch(e) {
                failed = true
                setModalMessage(e.errorMessage)
                setFailureModal(true)
            }
        } else {
            try{
                const signedData = await ual.activeUser.signTransaction({
                    actions: [{
                        account: process.env.REACT_APP_TOKENCONTRACT,
                        name: "transfer",
                        authorization: [{
                            actor: currentUser.account_name,
                            permission: "active"
                        }],
                        data: {
                            from: currentUser.account_name,
                            to: receiver,
                            quantity: `${Number(quantity).toFixed(4)} ${process.env.REACT_APP_TOKENNAME}`,
                            memo: `${telosEVMTo}`
                        }
                    }]
                }, {
                    blocksBehind: 3,
                    expireSeconds: 30,
                    broadcast: false
                });

                const signatureData = {
                    signatures: signedData.transaction.signatures,
                    packed_trx: arrayToHex(signedData.transaction.serializedTransaction)
                }
                //const backendSubmit = 
                await QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/eos/send`, {
                    from: currentUser.account_name,
                    to: receiver,
                    to_username: receiverInfo ? receiverInfo.username : null,
                    quantity: quantity,
                    memo: `${telosEVMTo}`,
                    signature: signatureData
                }, {
                    withCredentials: true
                })
            } catch (e) {
                failed = true
                setModalMessage(e.errorMessage)
                setFailureModal(true)
            }
        }
        if(failed)
            return;

        dispatch( _actions.updateBalance() )

        // from ZERO to TEVM only ? 
        if(chainSelected === chains.telosEVM.chainId){
            setModalMessage("Success");
            setSuccessModal(true);
            return;
        }

        // Making sure the user is RLY on TLOS
        actions.push(createAction({
            func: FUNC.SWITCH_NETWORK,
            args: [chains.telosEVM.chainId],
        }))

        // Zero to tEVM : ...
        actions.push(createAction({
            func: FUNC.WAIT_BALANCE,
            args: [quantity, chains.telosEVM.chainId],
            message: `Waiting for ${quantity.toFixed(4)} QUDO on ${chains.telosEVM.chainName}`,
        }))
        // Zero to tEVM : Done, bridged tokens to tEVM, now they are OFTs

        // TODO: DELETE ? THIS SEEMS WRONG IDK WHAT I DID HERE; WAS SLEEP ???
        //actions.push(createAction({
        //    func: FUNC.BRIDGE,
        //    args: [quantity, chains.telosEVM.chainId],
        //    message: `Waiting for ${quantity.toFixed(4)} QUDO on ${chains.telosEVM.chainName} to bridge into Telos Zero`,
        //}))

        actions.push(createAction({ 
            func: FUNC.BRIDGE,
            args:[to, quantity, chainSelected],
            options:{
                'source_chainId': chains.telosEVM.chainId,
                'success_message': `Success. The balance on ${selectedChain.chainName} will update shortly after`,
            },
            message: `Bridge from ${chains.telosEVM.chainName} to ${selectedChain.chainName}`,
        }))

        setActions(actions, true)
    }

    // MARK: Submit

    const beforeunloadFunction = (event) => {
        console.log("beforeunload event", event);
        event.preventDefault();
        event.returnValue = 'An operation is still in progress, leaving the page may interrupt its completion. Are you sure?';
    }

    async function submit() {
        if(chainSelected === -1) { setErrorMessage("Please select a chain"); return; }
        if(quantity <= 0) { setErrorMessage("Please enter a positive amount"); return; }
        if(quantity > maxBalance) { setErrorMessage("Insufficient balance"); return; }
        
        setSubmitting(true); 
        window.addEventListener('beforeunload', beforeunloadFunction)
        dispatch(_actions.setChangePageWarning('An operation is still in progress, leaving the page may interrupt its completion. Are you sure?'))
        
        //createAction({func: FUNC.PRINT_NETWORK, message: "Switching to tZERO Network", args: [chains.telosEVM.chainId]}),

        if(isEVM) {
            await submitEVM();
        } else {
            await submitTLOS();
        }

        setSubmitting(false);
        window.removeEventListener('beforeunload', beforeunloadFunction)
        dispatch(_actions.setChangePageWarning(''))
    }

    
    // MARK: Component
    return (
    <>
        <div className="card roundcard shadow" style={{ borderColor:`${detailColor?detailColor:''}` }} >
            <div className="row px-4 pt-4 pb-2">
                <div className="col-1">
                    <img src={send} className="mr-2" alt="send"/>
                </div>
                <div className="col-10">Transfer</div>
            </div>

            {!currentUser.qudoManaged && !ual.activeUser && !isEVM ?  // TODO: Another of this to check for eth
            ( <>
            <div className="card-body px-4 pb-0 pt-3">
                Please sign in with one of the available wallets to transfer QUDO
            </div>
            <div className="card-body">
                <button
                    type="button"
                    className="std-button-active"
                    onClick={() => {
                        ual.logout();
                        ual.restart();
                        ual.showModal();
                    }}
                >
                    <div className="text-center">
                        SIGN IN
                    </div>
                </button>
                <button
                    type="button"
                    className="std-button mb-0"
                    style={{backgroundColor: '#00cc69'}}
                    onClick={() => setTutorialModal(!tutorialModal)}
                >
                    <div className="text-center">
                        HOW TO SIGN IN
                    </div>
                </button>
            </div>
            </> ) : ( <>
        
                <div className="card-body px-4 pb-4 pt-0" >
                    <span>
                        Move my tokens across my QUDO chains
                    </span>

                    <div style={{margin:"12px 80px 12px 80px"}}>
                    <div className="mt-3 text-center">
                        <div className={`network-selector ${chainId === 0?'network-disabled':''}`}>
                            <label className="network-item">
                                Telos ZERO
                            </label>
                            <input
                                type="radio"
                                className="my-auto"
                                disabled={chainId === 0}
                                checked={chainSelected === 0}
                                onChange={() => setChainSelected(0)}
                            />
                        </div>
                        <div className={`network-selector ${chainId === chains.telosEVM.chainId?'network-disabled':''}`}>
                            <label className="network-item">
                                Telos EVM
                            </label>
                            <input
                                type="radio"
                                className="my-auto"
                                disabled={chainId === chains.telosEVM.chainId}
                                checked={chainSelected === chains.telosEVM.chainId}
                                onChange={() => setChainSelected(chains.telosEVM.chainId)}
                            />
                        </div>
                        <div className={`network-selector ${chainId === chains.base.chainId?'network-disabled':''}`}>
                            <label className="network-item">
                                BASE
                            </label>
                            <input
                                type="radio"
                                className="my-auto"
                                disabled={chainId === chains.base.chainId}
                                checked={chainSelected === chains.base.chainId}
                                onChange={() => setChainSelected(chains.base.chainId)}
                            />
                        </div>
                    </div>
                    </div>

                    {errorMessage && (
                        <div className="row mt-3" style={{color: 'red', fontSize: '0.85rem'}}>
                            <div className="col-12 text-center">
                                {errorMessage}
                            </div>
                        </div>
                    )}
                </div>

                <div className="card-body" style={{padding: "0 3rem"}}>
                    <div className="row justify-content-center">
                        <div className="col-12">Amount</div>
                    </div>
                    <div className="row justify-content-center" style={{borderBottom:`1px solid ${detailColor?detailColor:'#00000020'}`}}>
                        <input
                            type="number"
                            name="quantity"
                            className="col-12 col-xl-8 numbered-range-input"
                            style={{border: "none"}}
                            step={0.01}
                            value={quantity}
                            onChange={(e) => setQuantity(e.target.value)}
                            onBlur={() => confirmAmount(quantity, maxBalance, 0, setQuantity)}
                        />
                        <div className="col-12 col-xl-4 justify-content-right">
                            Qudo
                        </div>
                    </div>
                </div>

                <div className="card-body">
                    {loading ? 
                        actionsList.length > 0 ?
                        <button 
                            type="button"
                            className="std-button-active"
                            style={{
                                display:'flex',
                                alignItems:'center',
                                justifyContent:'space-between',
                                gap: '8px',
                            }}
                            onClick={()=>{
                                setActionsModal(true)
                            }}
                        >
                            SEE PROGRESS
                            <ClipLoader color="#282725"/>
                        </button>
                        : (
                        <div className="text-center">
                            <ClipLoader color="#282725"/>
                        </div>
                    ) : (
                        <button
                            onClick={() => { submit(); }}
                            type="button"
                            className="std-button-active"
                        >
                            <div className="text-center">
                                <div style={{ float: "left" }}>
                                    TRANSFER QUDO
                                </div>
                                <img
                                    style={{ float: "right" }}
                                    src={arrow}
                                    alt="arrow"
                                />
                            </div>
                        </button>
                    )}
                </div>
        
            </> )
            }
        </div>

        <MessageModal
            show={successModal} 
            message={modalMessage}
            hide={() => setSuccessModal(false)}
        />

        <MessageModal
            show={failureModal} 
            message={modalMessage}
            hide={() => setFailureModal(false)}
        />
        
        {tutorialModal && (
            <WalletImportTutorial
                show={tutorialModal}
                hide={() => setTutorialModal(false)}
            />
        )}

        {actionsModal && (
            <Modal show={actionsModal} onHide={() => setActionsModal(!actionsModal)}>
            <Modal.Header >
                <div className='w-100 text-center' id='title' style={{fontSize:'1.5rem'}} >
                    {unfinishedActions ? 'Continue previous transfer ?' : 'TRANSFER PROGRESS'}
                </div>
            </Modal.Header>
            <Modal.Body className="confirm-modal">
            <div style={{padding: "2rem 4rem"}}>
            <div className="confirm-modal-text text-center">
                <div>
                    <div style={{marginBottom:'8px'}}>
                        <h3 style={{textAlign:'left'}}>Actions</h3>
                        <div style={{
                            display:'flex',
                            flexDirection:'column',
                            gap:'1rem',
                            borderTop:'1px solid black',
                            padding:'16px 4px 8px',
                        }}>
                        { actionsList.map(( a, i ) => {
                            if(!a.message || a.message.trim() === '')
                                return null

                            return(<div>
                                <div key={`${a.func}-${i}`} style={{ 
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }} >
                                    <div> {a.message} </div>
                                    <div> {
                                        i < currentExecution ? EXECUTEDMSG :
                                        i === currentExecution ?
                                            actionsPaused ? '...' : CURREXECMSG : 
                                        WAITEXECMSG 
                                    } </div>
                                </div>
                                {a.error && a.error.trim() !== '' && 
                                <div style={{
                                    fontWeight:'lighter',
                                    color:'red',
                                    float:'left',
                                }}>
                                    {a.error}
                                </div>
                                }
                            </div>) }) } 
                        </div>
                    </div>
                    
                    <div style={{
                        display:'flex',
                        justifyContent:'flex-end',
                        gap: '16px',
                    }}>
                        <button 
                            type="button"
                            className="std-button-active-red transfer-modal-action-buttons"
                            onClick={()=>{
                                setActions()
                                setActionsPaused(true)
                                setUnfinishedActions(false)
                                setActionsModal(false)
                            }}
                        >
                            {unfinishedActions ? 'Discard' : 'Abort'}
                        </button>

                        <button 
                            type="button"
                            className="std-button-active-blue transfer-modal-action-buttons"
                            onClick={()=>{
                                setUnfinishedActions(false)
                                setActionsPaused(!actionsPaused)
                            }}
                        >
                            {actionsPaused ? 'Continue' : 'Pause'}
                        </button>
                    </div>

                </div>
            </div>
            </div>
            </Modal.Body>
            </Modal>
        )}


    </>
    )
}